import * as api from 'api';
import { SignInResponse } from 'api/auth/types';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { signInUserError, signInUserSuccess } from './actions';
import { SIGN_IN_USER_REQUEST } from './constants';
import { SignInActionTypes } from './types';

export function* signInUser({ payload }: SignInActionTypes) {
  try {
    const response: AxiosResponse<SignInResponse> = yield call(
      api.auth.signInUser,
      payload,
    );
    yield put(signInUserSuccess(response.data));
  } catch ({ response }) {
    yield put(signInUserError(response.data.message));
  }
}

export default function* signInPageSaga() {
  yield takeLatest(SIGN_IN_USER_REQUEST, signInUser);
}
