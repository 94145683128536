import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from './constants';
import { ForgotPasswordActionTypes, FormValues } from './types';

export const forgotPasswordRequestAction = (
  payload: FormValues,
): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordSuccessAction = (): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailureAction = (
  payload: number,
): ForgotPasswordActionTypes => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload,
});
