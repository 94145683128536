/**
 *
 * DeliveriesPage saga
 *
 */
import * as api from 'api';
import { ClientPagination } from 'api/clients/types';
import {
  AwsPhotoData,
  Company,
  DeliveriesPagination,
  Delivery,
  DeliveryDetail,
  DeliveryQueryType,
  DeliveryStatus,
  RequirePhotoEndpointData,
  UpdateDeliveryData,
} from 'api/delivery/types';
import { DriverDetails } from 'api/driverDetails/types';
import { DriverForMapData, DriversPagination } from 'api/drivers/types';
import { GetQuotesResponse } from 'api/quotes/types';
import { AxiosError, AxiosResponse } from 'axios';
import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { downloadAttachment } from '../../utils/downloadAttachment';
import {
  assignDriverFailure,
  assignDriverSuccess,
  getDeliveriesFailure,
  getDeliveriesSuccess,
  getDeliveryDetailFailure,
  getDeliveryDetailSuccess,
  getDriverDetailsFailure,
  getDriverDetailsSuccess,
  getDriversFailure,
  getDriversSuccess,
  searchClientsFailure,
  searchClientsSuccess,
  searchDriversFailure,
  searchDriversSuccess,
  updateDeliveryFailure,
  updateDeliverySuccess,
} from './actions';
import {
  ASSIGN_DRIVER_REQUEST,
  DELIVERY_PAGE_SIZE,
  DRIVER_DETAILS_REQUEST,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_DELIVERIES_REQUEST,
  GET_DELIVERY_DETAIL_REQUEST,
  GET_DRIVERS_REQUEST,
  GET_QUOTES_FAILURE,
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  SEARCH_CLIENTS_REQUEST,
  SEARCH_DRIVERS_REQUEST,
  UPDATE_DELIVERY_REQUEST,
} from './constants';
import {
  makeSelectDeliveryDetailDriver,
  makeSelectDeliveryDetailId,
  makeSelectDeliveryDetailStatus,
  makeSelectDeliveryFormValues,
  makeSelectDeliveryListPage,
  makeSelectDeliveryQueryType,
} from './selectors';
import {
  AssignDriverRequestAction,
  DeliveryFormValues,
  DriverDetailsRequestAction,
  GenerateReportRequestAction,
  GetDeliveriesRequestAction,
  GetDeliveriesRequestPayload,
  GetDeliveryDetailRequestAction,
  GetQuotesRequestAction,
  SearchClientsRequestAction,
  SearchDriversRequestAction,
  TemporaryDeliveryPhoto,
  UpdateDeliveryRequestAction,
} from './types';

export function* getDeliveries({ payload }: GetDeliveriesRequestAction) {
  try {
    const response: AxiosResponse<DeliveriesPagination> = yield call(
      api.delivery.getDeliveries,
      payload,
    );
    yield put(getDeliveriesSuccess(response.data));
  } catch (err) {
    const error = err as AxiosError;
    yield put(getDeliveriesFailure(error));
  }
}

export function* getDeliveryDetail({
  payload,
}: GetDeliveryDetailRequestAction) {
  try {
    const response: AxiosResponse<DeliveryDetail> = yield call(
      api.delivery.getDelivery,
      payload,
    );
    yield put(getDeliveryDetailSuccess(response.data));
  } catch (err) {
    const error = err as AxiosError;
    yield put(getDeliveryDetailFailure(error));
  }
}

export function* sendPhotoToAws(
  deliveryId: number,
  photo: TemporaryDeliveryPhoto,
) {
  const requirePhotoEndpointParams: RequirePhotoEndpointData = {
    deliveryId,
    format: photo.format,
    size: photo.file?.size,
  };
  const response: AxiosResponse<AwsPhotoData> = yield call(
    api.delivery.requirePhotoEndpoint,
    requirePhotoEndpointParams,
  );
  yield call(api.delivery.sendPhotoToAws, response.data.url, photo.file);
}

export function* deletePhoto(photo: TemporaryDeliveryPhoto) {
  yield call(api.delivery.deletePhoto, photo.id);
}

export const STATUS_ALLOWED_TO_CHANGE_LOAD_NEEDED: DeliveryStatus[] = [
  'created',
  'driver requested',
  'allocated',
  'arrived',
  'MOB'
];

export function* updateDelivery({
  payload: {
    status,
    photos,
    toCollect,
    toDeliver,
    deliveryTime,
    driverId,
    loadNeeded,
    notes,
  },
}: UpdateDeliveryRequestAction) {
  try {
    const deliveryId: number = yield select(makeSelectDeliveryDetailId());
    const deliveryDetailStatus: string = yield select(
      makeSelectDeliveryDetailStatus(),
    );
    const currentDriver: Delivery['driver'] = yield select(
      makeSelectDeliveryDetailDriver(),
    );
    const deliveryFormValues: DeliveryFormValues = yield select(
      makeSelectDeliveryFormValues(),
    );
    const temporaryPhotos = filter(photos, {
      temp: true,
    });
    yield all(
      map(temporaryPhotos, temporaryPhoto =>
        call(sendPhotoToAws, deliveryId, temporaryPhoto),
      ),
    );
    const photosToDelete = differenceBy(
      deliveryFormValues.photos,
      photos,
      'id',
    );
    yield all(
      map(photosToDelete, photoToDelete => call(deletePhoto, photoToDelete)),
    );
    const deliveryTimeDate: Date = deliveryTime as Date;
    const theLoadNeeded = includes(
      STATUS_ALLOWED_TO_CHANGE_LOAD_NEEDED,
      deliveryDetailStatus,
    )
      ? loadNeeded
      : undefined;
    const updateDeliveryParams: UpdateDeliveryData = {
      status,
      toDeliver,
      toCollect,
      loadNeeded: theLoadNeeded,
      deliveryTime: deliveryTimeDate.toISOString(),
      notes,
    };
    yield call(api.delivery.updateDelivery, deliveryId, updateDeliveryParams);

    if (driverId && Number(driverId) !== currentDriver?.id) {
      yield call(api.delivery.assignDriver, { driverId, deliveryId });
    }

    yield put(updateDeliverySuccess());

    const pageNumber: number = yield select(makeSelectDeliveryListPage());
    const queryType: DeliveryQueryType = yield select(
      makeSelectDeliveryQueryType(),
    );
    const getDeliveriesParams: GetDeliveriesRequestPayload = {
      pageNumber,
      queryType,
      pageSize: DELIVERY_PAGE_SIZE,
    };
    yield put({
      type: GET_DELIVERIES_REQUEST,
      payload: getDeliveriesParams,
    });
  } catch (err) {
    const error = err as AxiosError;
    yield put(updateDeliveryFailure(error));
  }
}

export function* getDrivers() {
  try {
    const response: AxiosResponse<DriverForMapData> = yield call(
      api.drivers.getMapDrivers,
    );
    yield put(getDriversSuccess(response.data.data));
  } catch (err) {
    const error = err as AxiosError;
    yield put(getDriversFailure(error));
  }
}

export function* searchDrivers({ payload }: SearchDriversRequestAction) {
  try {
    const response: AxiosResponse<DriversPagination> = yield call(
      api.drivers.getAdminDrivers,
      payload,
    );

    yield put(searchDriversSuccess(response.data.data));
  } catch (err) {
    const error = err as AxiosError;
    yield put(searchDriversFailure(error));
  }
}

export function* searchClients({ payload }: SearchClientsRequestAction) {
  try {
    const response: AxiosResponse<ClientPagination> = yield call(
      api.clients.getClients,
      payload,
    );

    yield put(searchClientsSuccess(response.data.data));
  } catch (err) {
    const error = err as AxiosError;
    yield put(searchClientsFailure(error));
  }
}

export function* getDriverDetails({ payload }: DriverDetailsRequestAction) {
  try {
    const response: AxiosResponse<DriverDetails> = yield call(
      api.driverDetails.getDriverDetails,
      payload,
    );
    yield put(getDriverDetailsSuccess(response.data));
  } catch (err) {
    const error = err as AxiosError;
    yield put(getDriverDetailsFailure(error));
  }
}

export function* assignDriver({ payload }: AssignDriverRequestAction) {
  try {
    yield call(api.delivery.assignDriver, payload);

    // Update delivery pagination list
    const pageNumber: number = yield select(makeSelectDeliveryListPage());
    const queryType: DeliveryQueryType = yield select(
      makeSelectDeliveryQueryType(),
    );
    const getDeliveriesParams: GetDeliveriesRequestPayload = {
      pageNumber,
      queryType,
      pageSize: DELIVERY_PAGE_SIZE,
    };
    yield put({
      type: GET_DELIVERIES_REQUEST,
      payload: getDeliveriesParams,
    });

    yield put(assignDriverSuccess());
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      toast.error(error.response.data.message);
    }
    yield put(assignDriverFailure(error));
  }
}

export function* getCompanies() {
  try {
    const response: AxiosResponse<Company[]> = yield call(
      api.delivery.getCompanies,
    );
    yield put({
      type: GET_COMPANIES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      toast.error(error.response.data.message);
    }
    yield put({
      type: GET_COMPANIES_FAILURE,
    });
  }
}

export function* generateReport({ payload }: GenerateReportRequestAction) {
  try {
    const response: AxiosResponse<any> = yield call(
      api.delivery.generateReport,
      payload,
    );
    downloadAttachment(response.data);
    yield put({
      type: GENERATE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      toast.error(error.response.data.message);
    }
    yield put({
      type: GENERATE_REPORT_FAILURE,
    });
  }
}

export function* getQuotes({ payload }: GetQuotesRequestAction) {
  try {
    const response: AxiosResponse<GetQuotesResponse> = yield call(
      api.quotes.getQuotes,
      payload,
    );
    yield put({ type: GET_QUOTES_SUCCESS, payload: response.data });
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      toast.error(error.response.data.message);
    }
    yield put({ type: GET_QUOTES_FAILURE });
  }
}

export default function* deliveriesPageSaga() {
  yield takeLatest(GET_DELIVERIES_REQUEST, getDeliveries);
  yield takeLatest(GET_DELIVERY_DETAIL_REQUEST, getDeliveryDetail);
  yield takeLatest(GET_DRIVERS_REQUEST, getDrivers);
  yield takeLatest(UPDATE_DELIVERY_REQUEST, updateDelivery);
  yield takeLatest(DRIVER_DETAILS_REQUEST, getDriverDetails);
  yield takeLatest(ASSIGN_DRIVER_REQUEST, assignDriver);
  yield takeLatest(GET_COMPANIES_REQUEST, getCompanies);
  yield takeLatest(GENERATE_REPORT_REQUEST, generateReport);
  yield takeLatest(SEARCH_DRIVERS_REQUEST, searchDrivers);
  yield takeLatest(SEARCH_CLIENTS_REQUEST, searchClients);
  yield takeLatest(GET_QUOTES_REQUEST, getQuotes);
}
