import {
  GET_BACKEND_VERSION_FAILURE,
  GET_BACKEND_VERSION_REQUEST,
  GET_BACKEND_VERSION_SUCCESS,
} from './constants';
import { BackendActionTypes } from './types';

export const getBackendVersion = (): BackendActionTypes => ({
  type: GET_BACKEND_VERSION_REQUEST,
});

export const getBackendVersionSuccess = (
  version: string,
): BackendActionTypes => ({
  type: GET_BACKEND_VERSION_SUCCESS,
  payload: version,
});

export const getBackendVersionFailure = (): BackendActionTypes => ({
  type: GET_BACKEND_VERSION_FAILURE,
});
