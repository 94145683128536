import { push } from 'connected-react-router';
import { useMemo } from 'react';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { ActionCreator, bindActionCreators } from 'redux';
import { RootState } from 'store/types';

type ReturnVoid<P extends Parameters<any>> = (...params: P) => void;

// eslint-disable-next-line
export const useAction = <A, C extends ActionCreator<A>, P extends Parameters<C>>(action: C): ReturnVoid<P> => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(action, dispatch), [
    action,
    dispatch,
  ]);
};

export const useSelector: TypedUseSelectorHook<RootState> = <TSelected>(
  selector: (state: RootState) => TSelected,
): TSelected => useReduxSelector(selector, shallowEqual);

export const usePush = (path: string): any => useAction(() => push(path));
