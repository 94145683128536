/**
 *
 * LanguageProvider reducer
 *
 */

import produce, { Draft } from 'immer';
import messages from 'translations';
import { DEFAULT_USER_LOCALE } from 'utils/locale';

import { CHANGE_LOCALE } from './constants';
import { LanguageActionTypes, LanguageProviderState } from './types';

const locale = DEFAULT_USER_LOCALE(messages);

export const initialState: LanguageProviderState = {
  locale,
};

const languageProviderReducer = produce(
  (draft: Draft<LanguageProviderState>, action: LanguageActionTypes) => {
    if (action.type === CHANGE_LOCALE) {
      draft.locale = action.payload;
    }
  },
  initialState,
);

export default languageProviderReducer;
