import SideMenu from 'components/SideMenu';
import { FeedbackMessage } from 'containers/FeedbackMessage';
import React, { FC } from 'react';
import { useAction, useSelector } from 'utils/hooks';

import { toggleSideMenu } from './actions';
import { makeSelectSideMenuOpened } from './selectors';

const Template: FC = ({ children }) => {
  const sideMenuOpened = useSelector(makeSelectSideMenuOpened());
  const toggleSideMenuAction = useAction(toggleSideMenu);
  return (
    <>
      <SideMenu toggleSideMenu={toggleSideMenuAction} open={sideMenuOpened} />
      <FeedbackMessage />
      <div className="admin-content-cride">{children}</div>
    </>
  );
};

export default Template;
