/**
 *
 * App actions
 *
 */

import { SignInResponse } from 'api/auth/types';
import { AxiosError } from 'axios';

import {
  CLEAR_TOKENS,
  LOGIN,
  LOGIN_SUCCESS,
  LOGOUT,
  SEND_FCM_TOKEN_FAILURE,
  SEND_FCM_TOKEN_REQUEST,
  SEND_FCM_TOKEN_SUCCESS,
  UPDATE_TOKEN,
} from './constants';
import { LoginActionTypes } from './types';

export const loginUser = (payload: SignInResponse): LoginActionTypes => ({
  type: LOGIN,
  payload,
});

export const loginUserSuccess = (): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
});

export const logoutUser = (): LoginActionTypes => ({
  type: LOGOUT,
});

export const clearTokens = (): LoginActionTypes => ({
  type: CLEAR_TOKENS,
});

export const updateToken = (token: string): LoginActionTypes => ({
  type: UPDATE_TOKEN,
  payload: token,
});

export const sendFCMToken = (fcmToken: string): LoginActionTypes => ({
  type: SEND_FCM_TOKEN_REQUEST,
  payload: fcmToken,
});

export const sendFCMTokenFailure = (error: AxiosError): LoginActionTypes => ({
  type: SEND_FCM_TOKEN_FAILURE,
  payload: error,
  error: true,
});

export const sendFCMTokenSuccess = (): LoginActionTypes => ({
  type: SEND_FCM_TOKEN_SUCCESS,
});
