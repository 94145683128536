import { SignInRequest, SignInResponse } from 'api/auth/types';

import {
  SIGN_IN_USER_FAILURE,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_SUCCESS,
} from './constants';
import { SignInActionTypes } from './types';

export const signInUser = (userData: SignInRequest): SignInActionTypes => ({
  type: SIGN_IN_USER_REQUEST,
  payload: userData,
});

export const signInUserSuccess = (
  payload: SignInResponse,
): SignInActionTypes => ({
  type: SIGN_IN_USER_SUCCESS,
  payload,
});

export const signInUserError = (error: string): SignInActionTypes => ({
  type: SIGN_IN_USER_FAILURE,
  payload: error,
});
