/**
 *
 * DeliveriesPage actions
 *
 */
import { Client, ClientRequestParams } from 'api/clients/types';
import {
  AssignDriverData,
  DeliveriesPagination,
  Delivery,
  DeliveryDetail,
  DeliveryQueryType,
  ReportQuery,
} from 'api/delivery/types';
import { DriverDetails } from 'api/driverDetails/types';
import { Driver, DriverForMap, DriversRequestParams } from 'api/drivers/types';
import { GetQuotesRequest } from 'api/quotes/types';
import { AxiosError } from 'axios';

import {
  ASSIGN_DRIVER_FAILURE,
  ASSIGN_DRIVER_REQUEST,
  ASSIGN_DRIVER_SUCCESS,
  CLEAR_QUOTES_DATA,
  CLEAR_REPORT_ERROR,
  DRIVER_DETAILS_FAILURE,
  DRIVER_DETAILS_REQUEST,
  DRIVER_DETAILS_SUCCESS,
  GENERATE_REPORT_REQUEST,
  GET_COMPANIES_REQUEST,
  GET_DELIVERIES_FAILURE,
  GET_DELIVERIES_REQUEST,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERY_DETAIL_FAILURE,
  GET_DELIVERY_DETAIL_REQUEST,
  GET_DELIVERY_DETAIL_SUCCESS,
  GET_DRIVERS_FAILURE,
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  GET_QUOTES_REQUEST,
  HIDE_DELIVERY_DETAIL_MODAL,
  SEARCH_CLIENTS_FAILURE,
  SEARCH_CLIENTS_REQUEST,
  SEARCH_CLIENTS_SUCCESS,
  SEARCH_DRIVERS_FAILURE,
  SEARCH_DRIVERS_REQUEST,
  SEARCH_DRIVERS_SUCCESS,
  SELECT_DELIVERY,
  SET_DELIVERY_LIST_PAGE,
  SET_DELIVERY_QUERY_TYPE,
  SET_IS_OPEN_REPORT_POPUP,
  SHOW_DELIVERY_DETAIL_MODAL,
  UPDATE_DELIVERY_FAILURE,
  UPDATE_DELIVERY_REQUEST,
  UPDATE_DELIVERY_SUCCESS,
} from './constants';
import {
  DeliveriesActionTypes,
  DeliveryFormValues,
  GetDeliveriesRequestPayload,
} from './types';

export const getDeliveries = (
  payload: GetDeliveriesRequestPayload,
): DeliveriesActionTypes => ({
  type: GET_DELIVERIES_REQUEST,
  payload,
});

export const getDeliveriesSuccess = (
  deliveries: DeliveriesPagination,
): DeliveriesActionTypes => ({
  type: GET_DELIVERIES_SUCCESS,
  payload: deliveries,
});

export const getDeliveriesFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: GET_DELIVERIES_FAILURE,
  payload: error,
  error: true,
});

export const updateDelivery = (
  payload: DeliveryFormValues,
): DeliveriesActionTypes => ({
  type: UPDATE_DELIVERY_REQUEST,
  payload,
});

export const updateDeliverySuccess = (): DeliveriesActionTypes => ({
  type: UPDATE_DELIVERY_SUCCESS,
});

export const updateDeliveryFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: UPDATE_DELIVERY_FAILURE,
  payload: error,
  error: true,
});

export const getDeliveryDetail = (id: number): DeliveriesActionTypes => ({
  type: GET_DELIVERY_DETAIL_REQUEST,
  payload: id,
});

export const getDeliveryDetailSuccess = (
  deliveryDetail: DeliveryDetail,
): DeliveriesActionTypes => ({
  type: GET_DELIVERY_DETAIL_SUCCESS,
  payload: deliveryDetail,
});

export const getDeliveryDetailFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: GET_DELIVERY_DETAIL_FAILURE,
  payload: error,
  error: true,
});

export const getDrivers = (): DeliveriesActionTypes => ({
  type: GET_DRIVERS_REQUEST,
});

export const getDriversSuccess = (
  drivers: DriverForMap[],
): DeliveriesActionTypes => ({
  type: GET_DRIVERS_SUCCESS,
  payload: drivers,
});

export const getDriversFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: GET_DRIVERS_FAILURE,
  payload: error,
  error: true,
});

export const selectDelivery = (delivery: Delivery): DeliveriesActionTypes => ({
  type: SELECT_DELIVERY,
  payload: delivery,
});

export const showDeliveryDetailModal = (): DeliveriesActionTypes => ({
  type: SHOW_DELIVERY_DETAIL_MODAL,
});

export const hideDeliveryDetailModal = (): DeliveriesActionTypes => ({
  type: HIDE_DELIVERY_DETAIL_MODAL,
});

export const getDriverDetails = (payload: number): DeliveriesActionTypes => ({
  type: DRIVER_DETAILS_REQUEST,
  payload,
});

export const getDriverDetailsSuccess = (
  payload: DriverDetails,
): DeliveriesActionTypes => ({
  type: DRIVER_DETAILS_SUCCESS,
  payload,
});

export const getDriverDetailsFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: DRIVER_DETAILS_FAILURE,
  payload: error,
  error: true,
});

export const assignDriver = (
  payload: AssignDriverData,
): DeliveriesActionTypes => ({
  type: ASSIGN_DRIVER_REQUEST,
  payload,
});

export const assignDriverSuccess = (): DeliveriesActionTypes => ({
  type: ASSIGN_DRIVER_SUCCESS,
});

export const assignDriverFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: ASSIGN_DRIVER_FAILURE,
  payload: error,
  error: true,
});

export const searchDrivers = (
  payload: DriversRequestParams,
): DeliveriesActionTypes => ({
  type: SEARCH_DRIVERS_REQUEST,
  payload,
});

export const searchDriversSuccess = (
  payload: Driver[],
): DeliveriesActionTypes => ({
  type: SEARCH_DRIVERS_SUCCESS,
  payload,
});

export const searchDriversFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: SEARCH_DRIVERS_FAILURE,
  payload: error,
  error: true,
});

export const searchClients = (
  payload: ClientRequestParams,
): DeliveriesActionTypes => ({
  type: SEARCH_CLIENTS_REQUEST,
  payload,
});

export const searchClientsSuccess = (
  payload: Client[],
): DeliveriesActionTypes => ({
  type: SEARCH_CLIENTS_SUCCESS,
  payload,
});

export const searchClientsFailure = (
  error: AxiosError,
): DeliveriesActionTypes => ({
  type: SEARCH_CLIENTS_FAILURE,
  payload: error,
  error: true,
});

export const setDeliveryQueryType = (
  payload: DeliveryQueryType,
): DeliveriesActionTypes => ({
  type: SET_DELIVERY_QUERY_TYPE,
  payload,
});

export const setDeliveryListPage = (
  payload: number,
): DeliveriesActionTypes => ({
  type: SET_DELIVERY_LIST_PAGE,
  payload,
});

export const getCompanies = (): DeliveriesActionTypes => ({
  type: GET_COMPANIES_REQUEST,
});

export const generateReport = (
  payload: ReportQuery,
): DeliveriesActionTypes => ({
  type: GENERATE_REPORT_REQUEST,
  payload,
});

export const clearErrorReport = (): DeliveriesActionTypes => ({
  type: CLEAR_REPORT_ERROR,
});

export const setOpenReportPopup = (
  payload: boolean,
): DeliveriesActionTypes => ({
  type: SET_IS_OPEN_REPORT_POPUP,
  payload,
});

export const getQuotes = (
  payload: GetQuotesRequest,
): DeliveriesActionTypes => ({
  type: GET_QUOTES_REQUEST,
  payload,
});

export const clearQuotesData = (): DeliveriesActionTypes => ({
  type: CLEAR_QUOTES_DATA,
});
