import { axios } from 'api/index';

import {
  ClientPagination,
  ClientRequestParams,
  UpdateClientRequest,
} from './types';

export const endpoints = {
  clients: 'admin/clients',
  update: (id: string | number) => `admin/clients/${id}`,
};

export default {
  getClients: ({ pageNumber, pageSize, search }: ClientRequestParams) =>
    axios.get<ClientPagination>(endpoints.clients, {
      params: {
        pageNumber,
        pageSize,
        search,
      },
    }),

  update: ({ id, password }: UpdateClientRequest) =>
    axios.put(endpoints.update(id), { password }),
};
