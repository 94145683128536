/**
 *
 * Template actions
 *
 */

import { TOGGLE_SIDE_MENU } from './constants';
import { TemplateActionTypes } from './types';

export const toggleSideMenu = (): TemplateActionTypes => ({
  type: TOGGLE_SIDE_MENU,
});
