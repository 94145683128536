/* eslint-disable no-unused-vars */
import { axios } from 'api';
import rootAxios from 'axios';

import {
  AssignDriverData,
  Company,
  DeliveriesPagination,
  DeliveryDetail,
  DeliveryLoadTypeMap,
  DeliveryQueryType,
  DeliveryStatus,
  GetDeliveriesParams,
  ReportQuery,
  RequirePhotoEndpointData,
  UpdateDeliveryData,
} from './types';

export const endpoints = {
  deliveries: 'admin/deliveries',
  assignments: 'admin/assignments',
  companies: 'companies',
  report: 'admin/report',
};

export const deliveryStatusList: DeliveryStatus[] = [
  'created',
  'driver requested',
  'allocated',
  'arrived',
  'MOB',
  'to clear',
  'cleared',
  'invoiced',
  'canceled',
];

export const deliveryQueryTypeList: DeliveryQueryType[] = [
  'Next Hour',
  'Next 4 Hours',
  'Future Jobs',
  'Cleared',
  'All',
];

export const deliveryLoadTypeMap: DeliveryLoadTypeMap = {
  empty: 0,
  quarter: 1,
  half: 2,
  'three-quarters': 3,
  full: 4,
};

export default {
  getDeliveries: (params: GetDeliveriesParams) =>
    axios.get<DeliveriesPagination>(endpoints.deliveries, {
      params,
    }),
  getDelivery: (id: number) =>
    axios.get<DeliveryDetail>(`${endpoints.deliveries}/${id}`),
  updateDelivery: (id: number, data: UpdateDeliveryData) =>
    axios.put(`${endpoints.deliveries}/${id}`, data),
  requirePhotoEndpoint: (data: RequirePhotoEndpointData) =>
    axios.post('/deliveries/photos', data),
  sendPhotoToAws: (url: string, file: File) =>
    rootAxios.put(url, file, {
      headers: { 'Content-Type': file.type },
    }),
  deletePhoto: (photoId: string) =>
    axios.delete(`deliveries/photos/${photoId}`),
  assignDriver: (data: AssignDriverData) =>
    axios.post(endpoints.assignments, data),
  getCompanies: () => axios.get<Company>(endpoints.companies),
  generateReport: (data: ReportQuery) =>
    axios.get(endpoints.report, {
      responseType: 'blob',
      params: {
        ...data,
      },
    }),
};
