import * as api from 'api';
import {
  forgotPasswordFailureAction,
  forgotPasswordSuccessAction,
} from 'containers/ForgotPasswordPage/actions';
import { FORGOT_PASSWORD_REQUEST } from 'containers/ForgotPasswordPage/constants';
import { ForgotPasswordRequestAction } from 'containers/ForgotPasswordPage/types';
import { paths } from 'containers/RoutesProvider/routes';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

export function* changePassword({ payload }: ForgotPasswordRequestAction) {
  try {
    yield call(api.auth.resetPassword, payload);
    yield put(forgotPasswordSuccessAction());
    window.location.assign(paths.onboardingPage);
  } catch ({ response }) {
    if (response.data) {
      toast.error(response.data.message);
    }
    yield put(forgotPasswordFailureAction(response.status));
  }
}
export default function* forgotPasswordPageSaga() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, changePassword);
}
