export const DRIVERS_REQUEST = 'DriversPage/DRIVERS_REQUEST';
export const DRIVERS_FAILURE = 'DriversPage/DRIVERS_FAILURE';
export const DRIVERS_SUCCESS = 'DriversPage/DRIVERS_SUCCESS';
export const DRIVER_REQUEST = 'DriversPage/DRIVER_REQUEST';
export const DRIVER_FAILURE = 'DriversPage/DRIVER_FAILURE';
export const DRIVER_SUCCESS = 'DriversPage/DRIVER_SUCCESS';
export const CLEAN_DRIVER = 'DriversPage/CLEAN_DRIVER';
export const ADD_DRIVER = 'DriversPage/ADD_DRIVER';
export const ADD_DRIVER_FAILURE = 'DriversPage/ADD_DRIVER_FAILURE';
export const ADD_DRIVER_SUCCESS = 'DriversPage/ADD_DRIVER_SUCCESS';
export const UPDATE_DRIVER = 'DriversPage/UPDATE_DRIVER';
export const UPDATE_DRIVER_FAILURE = 'DriversPage/UPDATE_DRIVER_FAILURE';
export const UPDATE_DRIVER_SUCCESS = 'DriversPage/UPDATE_DRIVER_SUCCESS';
export const DELETE_DRIVER = 'DriversPage/DELETE_DRIVER';
export const DELETE_DRIVER_FAILURE = 'DriversPage/DELETE_DRIVER_FAILURE';
export const DELETE_DRIVER_SUCCESS = 'DriversPage/DELETE_DRIVER_SUCCESS';
export const GET_COMPANIES_REQUEST = 'DriversPage/GET_COMPANIES_REQUEST';
export const GET_COMPANIES_FAILURE = 'DriversPage/GET_COMPANIES_FAILURE';
export const GET_COMPANIES_SUCCESS = 'DriversPage/GET_COMPANIES_SUCCESS';
export const GET_STATES_REQUEST = 'DriversPage/GET_STATES_REQUEST';
export const GET_STATES_FAILURE = 'DriversPage/GET_STATES_FAILURE';
export const GET_STATES_SUCCESS = 'DriversPage/GET_STATES_SUCCESS';
export const GET_DRIVER_STATUS_REQUEST =
  'DriversPage/GET_DRIVER_STATUS_REQUEST';
export const GET_DRIVER_STATUS_FAILURE =
  'DriversPage/GET_DRIVER_STATUS_FAILURE';
export const GET_DRIVER_STATUS_SUCCESS =
  'DriversPage/GET_DRIVER_STATUS_SUCCESS';
