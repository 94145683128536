/**
 *
 * SideMenu
 *
 */

import { ReactComponent as Logo } from 'assets/images/cride-logo.svg';
import { ReactComponent as IconClients } from 'assets/images/icon-client.svg';
import { ReactComponent as IconCompanies } from 'assets/images/icon-company.svg';
import { ReactComponent as IconDrivers } from 'assets/images/icon-driver.svg';
import { ReactComponent as IconDeliveries } from 'assets/images/icon-list.svg';
import { ReactComponent as IconLogout } from 'assets/images/icon-logout.svg';
import ProfilePlaceholder from 'assets/images/profile-placeholder.jpg';
import classNames from 'classnames';
import AppVersion from 'components/AppVersion';
import { logoutUser } from 'containers/App/actions';
import { paths } from 'containers/RoutesProvider/routes';
import React, { CSSProperties, FC } from 'react';
import { Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useAction, usePush } from 'utils/hooks';

import messages from './messages';

interface Props {
  style?: CSSProperties;
  className?: string;
  dataTestId?: string;
  toggleSideMenu(): void;
  open: boolean;
}

const SideMenu: FC<Props> = ({
  style,
  className,
  toggleSideMenu,
  open,
  dataTestId,
}) => {
  const { formatMessage } = useIntl();
  const logoutUserCallback = useAction(logoutUser);
  const goToDeliveries = usePush(paths.deliveriesPage);
  const goToDrivers = usePush(paths.driversPage);
  const goToClients = usePush(paths.clientsPage);
  const goToDriverCompanies = usePush(paths.driverCompaniesPage);

  const {
    location: { pathname },
  } = useHistory();

  return (
    <>
      <div
        style={style}
        className={classNames(
          'sidemenu-cride bg-dark sidebar flex-column sticky-top mr-1',
          { closed: !open },
          className,
        )}
        data-testid={dataTestId}
      >
        <button
          type="button"
          className="sidemenu-cride_arrow-back border-0 bg-transparent"
          onClick={toggleSideMenu}
        >
          <div className="sidemenu-cride_menu-trigger">
            <span />
          </div>
        </button>
        <div className="sidemenu-cride_logo d-flex">
          <Logo
            width="156"
            height="160"
            className="mx-auto sidemenu-cride_logo-svg"
          />
        </div>
        <div className="sidemenu-cride_profile d-flex align-items-center">
          <Image
            src={ProfilePlaceholder}
            width="45"
            height="45"
            className="d-inline-block"
            alt="Your profile"
            roundedCircle
          />
          <span className="sidemenu-cride_profile-name ml-3">John Smith</span>
        </div>
        <div className="sidemenu-cride_links">
          <h1 className="sidemenu-cride_links-title mb-3 text-center text-uppercase">
            {formatMessage(messages.manageDeliveries)}
          </h1>
          <ul className="sidemenu-cride_links__list">
            <li className="sidemenu-cride_links__list-item">
              <button
                type="button"
                onClick={goToDeliveries}
                className={classNames(
                  'sidemenu-cride_links__list-item-link d-flex border-0',
                  {
                    active: pathname === paths.deliveriesPage,
                  },
                )}
              >
                <IconDeliveries
                  width="25"
                  height="25"
                  className="sidemenu-cride_links__list-item-link-icon d-inline-block"
                />
                <span className="sidemenu-cride_links__list-item-link-text">
                  {formatMessage(messages.listDeliveries)}
                </span>
              </button>
            </li>
            <li className="sidemenu-cride_links__list-item">
              <button
                type="button"
                onClick={goToDrivers}
                className={classNames(
                  'sidemenu-cride_links__list-item-link d-flex border-0',
                  {
                    active: pathname === paths.driversPage,
                  },
                )}
              >
                <IconDrivers
                  width="25"
                  height="25"
                  className="sidemenu-cride_links__list-item-link-icon d-inline-block"
                />
                <span className="sidemenu-cride_links__list-item-link-text">
                  {formatMessage(messages.listDrivers)}
                </span>
              </button>
            </li>
            <li className="sidemenu-cride_links__list-item">
              <button
                type="button"
                onClick={goToClients}
                className={classNames(
                  'sidemenu-cride_links__list-item-link d-flex border-0',
                  {
                    active: pathname === paths.clientsPage,
                  },
                )}
              >
                <IconClients
                  width="25"
                  height="25"
                  className="sidemenu-cride_links__list-item-link-icon d-inline-block"
                />
                <span className="sidemenu-cride_links__list-item-link-text">
                  {formatMessage(messages.listClients)}
                </span>
              </button>
            </li>
            <li className="sidemenu-cride_links__list-item">
              <button
                type="button"
                onClick={goToDriverCompanies}
                className={classNames(
                  'sidemenu-cride_links__list-item-link d-flex border-0 align-items-center',
                  {
                    active: pathname === paths.driverCompaniesPage,
                  },
                )}
              >
                <IconCompanies
                  width="25"
                  height="25"
                  className="sidemenu-cride_links__list-item-link-icon d-inline-block"
                />
                <span className="sidemenu-cride_links__list-item-link-text text-left">
                  {formatMessage(messages.listDriverCompanies)}
                </span>
              </button>
            </li>
            <li className="sidemenu-cride_links__list-item">
              <button
                type="button"
                onClick={logoutUserCallback}
                className="sidemenu-cride_links__list-item-link d-flex border-0"
              >
                <IconLogout
                  width="25"
                  height="25"
                  className="sidemenu-cride_links__list-item-link-icon d-inline-block"
                />
                <span className="sidemenu-cride_links__list-item-link-text">
                  {formatMessage(messages.logout)}
                </span>
              </button>
            </li>
          </ul>

          <AppVersion sideMenuOpen={open} />
        </div>
      </div>
    </>
  );
};

export default SideMenu;
