import { DriverPhotoLicense, DriverForm } from 'api/drivers/types';
import { TemporaryDeliveryPhoto } from 'containers/DeliveriesPage/types';
import produce, { Draft } from 'immer';
import map from 'lodash/map';

import {
  ADD_DRIVER,
  ADD_DRIVER_FAILURE,
  ADD_DRIVER_SUCCESS,
  CLEAN_DRIVER,
  DELETE_DRIVER,
  DELETE_DRIVER_FAILURE,
  DELETE_DRIVER_SUCCESS,
  DRIVER_REQUEST,
  DRIVER_SUCCESS,
  DRIVERS_FAILURE,
  DRIVERS_REQUEST,
  DRIVERS_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_DRIVER_STATUS_FAILURE,
  GET_DRIVER_STATUS_REQUEST,
  GET_DRIVER_STATUS_SUCCESS,
  GET_STATES_SUCCESS,
  UPDATE_DRIVER,
  UPDATE_DRIVER_FAILURE,
  UPDATE_DRIVER_SUCCESS,
} from './constants';
import { DriversActionTypes, DriversState } from './types';

export const initialState: DriversState = {
  driversPagination: {
    totalCount: 0,
    pageNumber: 0,
    totalPages: 1,
    data: [],
  },
  driver: {
    id: '',
    email: '',
    password: '',
    verifyPassword: '',
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    brand: '',
    model: '',
    size: '',
    color: '',
    registration: '',
    license: {},
    photos: [],
    driverCompanyId: 0,
  },
  loading: false,
  hasError: false,
  states: [],
  companies: [],
  status: {
    isLoading: false,
    error: '',
    data: [],
  },
};

const driversPageReducer = produce(
  (draft: Draft<DriversState>, action: DriversActionTypes) => {
    switch (action.type) {
      case DRIVERS_REQUEST:
      case ADD_DRIVER:
      case UPDATE_DRIVER:
      case DELETE_DRIVER:
      case GET_COMPANIES_REQUEST:
        draft.loading = true;
        break;
      case DRIVERS_FAILURE:
      case ADD_DRIVER_FAILURE:
      case UPDATE_DRIVER_FAILURE:
      case DELETE_DRIVER_FAILURE:
      case GET_COMPANIES_FAILURE:
        draft.loading = false;
        draft.hasError = true;
        draft.error = action.payload;
        break;
      case DRIVERS_SUCCESS:
        draft.loading = false;
        draft.driversPagination = action.payload;
        break;
      case DRIVER_REQUEST:
        draft.loading = true;
        draft.driver = { ...(initialState.driver as DriverForm) };
        break;
      case DRIVER_SUCCESS:
        draft.loading = false;
        draft.driver = {
          ...initialState.driver,
          ...action.payload,
          photos: map(
            action.payload.licenseFiles,
            (licenseFile: DriverPhotoLicense) => {
              const temporaryDeliveryPhoto: TemporaryDeliveryPhoto = {
                id: Number(licenseFile.id),
                temp: false,
                path: licenseFile.url,
                type: '',
                deliveryId: 0,
              };
              return temporaryDeliveryPhoto;
            },
          ),
        };
        break;
      case ADD_DRIVER_SUCCESS:
      case UPDATE_DRIVER_SUCCESS:
      case DELETE_DRIVER_SUCCESS:
        draft.loading = false;
        break;
      case CLEAN_DRIVER:
        draft.driver = initialState.driver;
        break;
      case GET_STATES_SUCCESS:
        draft.states = action.payload;
        break;
      case GET_COMPANIES_SUCCESS:
        draft.companies = action.payload;
        break;
      case GET_DRIVER_STATUS_REQUEST:
        draft.status.isLoading = true;
        draft.status.data = [];
        draft.status.error = '';
        break;
      case GET_DRIVER_STATUS_SUCCESS:
        draft.status.isLoading = false;
        draft.status.data = action.payload;
        break;
      case GET_DRIVER_STATUS_FAILURE:
        draft.status.isLoading = false;
        draft.status.error = action.payload.message;
        break;
      default:
    }
  },
  initialState,
);

export default driversPageReducer;
