import ToastMessage from 'components/ToastMessage';
import firebase from 'firebase/app';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAction, useSelector } from 'utils/hooks';

import { sendFCMToken } from './actions';
import { VAPID_KEY } from './constants';
import { makeSelectAuthenticate } from './selectors';

export const useFirebase = () => {
  const sendFCMTokenAction = useAction(sendFCMToken);
  const isAuthenticated = useSelector(makeSelectAuthenticate());
  useEffect(() => {
    if (isAuthenticated && firebase.messaging.isSupported()) {
      const fbMessaging = firebase.messaging();
      fbMessaging
        .getToken({ vapidKey: VAPID_KEY })
        .then(fcmToken => {
          sendFCMTokenAction(fcmToken);
        })
        .catch(() =>
          toast.error('An error occurred while retrieving the firebase token.'),
        );

      // listening for foreground notifications
      fbMessaging.onMessage(payload => {
        toast.info(
          <ToastMessage
            title={payload?.notification?.title || ''}
            body={payload?.notification?.body || ''}
          />,
        );
        return toast(payload.data);
      });
    }
  }, [sendFCMTokenAction, isAuthenticated]);
};
