import { CHANGE_LOCALE } from './constants';

export enum Locale {
  EN = 'en',
  FR = 'fr',
}

export interface LanguageProviderState {
  locale: string;
}

export interface ChangeLocaleAction {
  type: typeof CHANGE_LOCALE;
  payload: string;
}

export type LanguageActionTypes = ChangeLocaleAction;
