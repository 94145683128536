import * as api from 'api';
import {
  AwsInsurancePhotoData,
  CompanyInsurance,
  DriverCompaniesPagination,
  InsurancePhotoRequest,
} from 'api/driverCompanies/types';
import { GetDriversResponse } from 'api/drivers/types';
import { AxiosError, AxiosResponse } from 'axios';
import { TemporaryDeliveryPhoto } from 'containers/DeliveriesPage/types';
import { makeSelectDriverCompanyPageNumber } from 'containers/DriverCompaniesPage/selectors';
import { updateSuccess } from 'containers/FeedbackMessage/actions';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getCompanyInsuranceFailure,
  getCompanyInsuranceSuccess,
  getDriverCompanies as getDriverCompaniesAction,
  getDriverCompaniesFailure,
  getDriverCompaniesSuccess,
  getDriversFailureAction,
  getDriversSuccessAction,
  updateCompanyInsuranceSuccess,
  updateDriverCompanyFailure,
  updateDriverCompanySuccess,
} from './actions';
import {
  DRIVER_COMPANIES_PAGE_SIZE,
  GET_COMPANY_INSURANCE_REQUEST,
  GET_DRIVER_COMPANIES_REQUEST,
  GET_DRIVERS_REQUEST,
  UPDATE_COMPANY_INSURANCE_REQUEST,
  UPDATE_DRIVER_COMPANY_REQUEST,
} from './constants';
import {
  DriverCompaniesActionTypes,
  GetDriversRequestAction,
  UpdateDriverCompanyRequestAction,
} from './types';

export function* getDrivers({ payload }: GetDriversRequestAction) {
  try {
    const response: AxiosResponse<GetDriversResponse> = yield call(
      api.drivers.getDrivers,
      payload,
    );
    yield put(getDriversSuccessAction(response.data));
  } catch (error) {
    yield put(getDriversFailureAction(error));
  }
}

export function* getDriverCompanies({ payload }: DriverCompaniesActionTypes) {
  try {
    const response: AxiosResponse<DriverCompaniesPagination> = yield call(
      api.driverCompanies.getDriverCompanies,
      payload,
    );
    yield put(getDriverCompaniesSuccess(response.data));
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      yield put(getDriverCompaniesFailure(error.response.status));
    }
  }
}

export function* getCompanyInsurance({ payload }: DriverCompaniesActionTypes) {
  try {
    const response: AxiosResponse<CompanyInsurance> = yield call(
      api.driverCompanies.getCompanyInsurance,
      payload,
    );
    yield put(getCompanyInsuranceSuccess(response.data));
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      yield put(getCompanyInsuranceFailure(error.response.status));
    }
  }
}

export function* sendPhotoToAws(
  companyInsuranceId: number,
  photo: TemporaryDeliveryPhoto,
) {
  const data: InsurancePhotoRequest = {
    companyInsuranceId,
    size: photo.file?.size,
    format: photo.format,
  };
  const response: AxiosResponse<AwsInsurancePhotoData> = yield call(
    api.driverCompanies.requireCompanyInsurancePhotoUrls,
    data,
  );
  yield call(
    api.driverCompanies.sendPhotoToAws,
    response.data.putUrl,
    photo.file,
  );
}

export function* updateDriverCompany({
  payload: { driverCompany, companyInsurance, photos },
}: UpdateDriverCompanyRequestAction) {
  try {
    const companyId = driverCompany.id;
    const insuranceResponse: AxiosResponse = yield call(
      companyInsurance.id
        ? api.driverCompanies.updateCompanyInsurance
        : api.driverCompanies.addCompanyInsurance,
      {
        ...companyInsurance,
        companyId,
        id: companyInsurance.id ? companyInsurance.id : undefined,
      },
    );
    const companyInsuranceId = companyInsurance.id || insuranceResponse.data.id;
    const temporaryPhotos = photos.filter(({ temp }) => temp);
    yield all(
      temporaryPhotos.map(photo =>
        call(sendPhotoToAws, companyInsuranceId, photo),
      ),
    );
    yield put(updateCompanyInsuranceSuccess(insuranceResponse.status));
    const driverResponse: AxiosResponse = yield call(
      api.driverCompanies.updateDriverCompany,
      {
        ...driverCompany,
        ownerId: Number(driverCompany.owner.id.toString()),
      },
    );
    yield put(updateDriverCompanySuccess(driverResponse.status));
    yield put(updateSuccess());
    const pageNumber: number = yield select(
      makeSelectDriverCompanyPageNumber(),
    );
    yield call(
      getDriverCompanies,
      getDriverCompaniesAction({
        pageNumber,
        pageSize: DRIVER_COMPANIES_PAGE_SIZE,
      }),
    );
  } catch (err) {
    const error = err as AxiosError;
    if (error?.response?.data) {
      toast.error(error.response.data.message);
      yield put(updateDriverCompanyFailure(error.response.status));
    }
  }
}

export default function* driverCompaniesPageSaga() {
  yield takeLatest(GET_DRIVER_COMPANIES_REQUEST, getDriverCompanies);
  yield takeLatest(GET_COMPANY_INSURANCE_REQUEST, getCompanyInsurance);
  yield takeLatest(GET_DRIVERS_REQUEST, getDrivers);
  yield takeLatest(UPDATE_DRIVER_COMPANY_REQUEST, updateDriverCompany);
  yield takeLatest(UPDATE_COMPANY_INSURANCE_REQUEST, updateDriverCompany);
}
