/**
 *
 * App reducer
 *
 */

import produce, { Draft } from 'immer';

import { CLEAR_TOKENS, LOGIN, UPDATE_TOKEN } from './constants';
import { AppState, LoginActionTypes } from './types';

export const initialState: AppState = {
  token: '',
  refreshToken: '',
};

const appReducer = produce(
  (draft: Draft<AppState>, action: LoginActionTypes) => {
    switch (action.type) {
      case LOGIN:
        draft.token = action.payload.authToken;
        draft.refreshToken = action.payload.refreshToken;
        break;
      case UPDATE_TOKEN:
        draft.token = action.payload;
        break;
      case CLEAR_TOKENS:
        return initialState;
      default:
    }
  },
  initialState,
);

export default appReducer;
