import appSaga from 'containers/App/saga';
import backendSaga from 'containers/Backend/saga';
import clientsPageSaga from 'containers/ClientsPage/saga';
import deliveriesPageSaga from 'containers/DeliveriesPage/saga';
import driverCompaniesPageSaga from 'containers/DriverCompaniesPage/saga';
import driversPageSaga from 'containers/DriversPage/saga';
import forgotPasswordPageSaga from 'containers/ForgotPasswordPage/saga';
import signInPageSaga from 'containers/SignInPage/saga';
import { fork } from 'redux-saga/effects';

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(signInPageSaga);
  yield fork(deliveriesPageSaga);
  yield fork(driversPageSaga);
  yield fork(forgotPasswordPageSaga);
  yield fork(clientsPageSaga);
  yield fork(driverCompaniesPageSaga);
  yield fork(backendSaga);
}
