import { axios } from '../index';
import { GetBackendVersionResponse } from './types';

export const endpoints = {
  version: 'health',
};

export default {
  getVersion: () => axios.get<GetBackendVersionResponse>(endpoints.version),
};
