import { axios } from '../index';
export const endpoints = {
  fcm: 'admin/fcm',
};

export default {
  sendNotificationsToken: (fcmToken: string, isEnabled = true) =>
    axios.post(`${endpoints.fcm}`, { fcmToken, isEnabled }),
  disableNotifications: () =>
    axios.post(`${endpoints.fcm}`, { isEnabled: false }),
};
