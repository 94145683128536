import firebase from 'firebase/app';

const setupFirebase = () => {
  // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  const firebaseConfig = {
    apiKey: 'AIzaSyB1aphJiDvWAVZwUBMl3pGsFA3oB-XG3Qc',
    authDomain: 'cride-df28e.firebaseapp.com',
    databaseURL: 'https://cride-df28e.firebaseio.com',
    projectId: 'cride-df28e',
    storageBucket: 'cride-df28e.appspot.com',
    messagingSenderId: '594441253113',
    appId: '1:594441253113:web:99a472983a264bddfb7228',
    measurementId: 'G-7VMJN1RW7B',
  };

  // Initialize Firebase if its supported
  if (firebase.messaging.isSupported()) {
    firebase.initializeApp(firebaseConfig);
  }
};

export default setupFirebase;
