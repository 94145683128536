import produce, { Draft } from 'immer';

import {
  SIGN_IN_USER_FAILURE,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_SUCCESS,
} from './constants';
import { SignInActionTypes, SignInState } from './types';

export const initialState: SignInState = {
  loading: false,
  hasError: false,
  error: undefined,
};

const signInPageReducer = produce(
  (draft: Draft<SignInState>, action: SignInActionTypes) => {
    switch (action.type) {
      case SIGN_IN_USER_REQUEST:
        draft.loading = true;
        draft.hasError = false;
        draft.error = undefined;
        break;
      case SIGN_IN_USER_FAILURE:
        draft.loading = false;
        draft.hasError = true;
        draft.error = action.payload;
        break;
      case SIGN_IN_USER_SUCCESS:
        return initialState;
      default:
    }
  },
  initialState,
);

export default signInPageReducer;
