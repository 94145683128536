import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { initialState } from './reducer';

const selectFeedbackMessageStateDomain = (state: RootState) =>
  state.feedbackMessage || initialState;

const makeSelectFeedbackMessageId = () =>
  createSelector(selectFeedbackMessageStateDomain, ({ id }) => id);

const makeSelectFeedbackMessageType = () =>
  createSelector(
    selectFeedbackMessageStateDomain,
    ({ messageType }) => messageType,
  );

export default makeSelectFeedbackMessageId;
export { makeSelectFeedbackMessageType };
