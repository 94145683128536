import produce, { Draft } from 'immer';
import { v4 as uuidv4 } from 'uuid';

import { DELETE_SUCCESS, SAVE_SUCCESS, UPDATE_SUCCESS } from './constants';
import { FeedbackMessageActionTypes, FeedbackMessageState } from './types';

export const initialState: FeedbackMessageState = {
  isActive: false,
  id: '',
};

const feedbackMessageReducer = produce(
  (draft: Draft<FeedbackMessageState>, action: FeedbackMessageActionTypes) => {
    switch (action.type) {
      case SAVE_SUCCESS:
        draft.isActive = true;
        draft.messageType = 'save';
        draft.id = uuidv4();
        break;
      case UPDATE_SUCCESS:
        draft.isActive = true;
        draft.messageType = 'update';
        draft.id = uuidv4();
        break;
      case DELETE_SUCCESS:
        draft.isActive = true;
        draft.messageType = 'delete';
        draft.id = uuidv4();
        break;
      default:
    }
  },
  initialState,
);

export default feedbackMessageReducer;
