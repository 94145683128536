import * as api from 'api';
import { Company } from 'api/delivery/types';
import {
  AwsLicensePhotoData,
  Driver,
  DriverForm,
  DriversPagination,
  GetDriverStatusResponse,
  LicensePhotoRequest,
} from 'api/drivers/types';
import { AxiosResponse } from 'axios';
import { TemporaryDeliveryPhoto } from 'containers/DeliveriesPage/types';
import {
  deleteSuccess,
  saveSuccess,
  updateSuccess,
} from 'containers/FeedbackMessage/actions';
import filter from 'lodash/filter';
import map from 'lodash/map';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { DEFAULT_TIMEZONE } from 'utils/constants';

import {
  addDriverError,
  addDriverSuccess,
  deleteDriverError,
  deleteDriverSuccess,
  getCompaniesError,
  getCompaniesSuccess,
  getDriverError,
  getDrivers as getDriversAction,
  getDriversError,
  getDriversSuccess,
  getDriverStatusFailureAction,
  getDriverStatusSuccessAction,
  getDriverSuccess,
  updateDriverError,
  updateDriverSuccess,
} from './actions';
import {
  ADD_DRIVER,
  DELETE_DRIVER,
  DRIVER_REQUEST,
  DRIVERS_REQUEST,
  GET_COMPANIES_REQUEST,
  GET_DRIVER_STATUS_REQUEST,
  GET_STATES_FAILURE,
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  UPDATE_DRIVER,
} from './constants';
import {
  AddDriverAction,
  DeleteDriverAction,
  DriversRequestAction,
  UpdateDriverAction,
} from './types';

export function* getDrivers({ payload }: DriversRequestAction) {
  try {
    const response: AxiosResponse<DriversPagination> = yield call(
      api.drivers.getAdminDrivers,
      payload,
    );
    yield put(getDriversSuccess(response.data));
  } catch ({ response }) {
    toast.error(response?.data?.message || '');
    yield put(getDriversError(response.status));
  }
}

export function* getDriver({ payload }: DriversRequestAction) {
  try {
    const response: AxiosResponse<Driver> = yield call(
      api.drivers.getDriver,
      payload,
    );
    let driver: Driver;
    if (response.data?.license?.expireDate) {
      driver = {
        ...response.data,
        license: {
          ...response.data.license,
          expireDate: moment(response.data.license.expireDate)
            .tz(DEFAULT_TIMEZONE)
            .format('MM/DD/YYYY'),
        },
      };
    } else {
      driver = response.data;
    }
    yield put(getDriverSuccess(driver));
  } catch ({ response }) {
    toast.error(response?.data?.message || '');
    yield put(getDriverError(response.status));
  }
}

const formatExpireDateBeforeSubmit = (
  driverForm: Omit<DriverForm, 'photos'>,
) => {
  if (driverForm?.license?.expireDate) {
    return {
      ...driverForm,
      license: {
        ...driverForm.license,
        expireDate: moment(
          driverForm.license.expireDate,
          'MM/DD/YYYY',
        ).toISOString(),
      },
    };
  }
  return driverForm;
};

export function* addDriver({
  payload: { driverForm, requestParams },
}: AddDriverAction) {
  try {
    const formData = formatExpireDateBeforeSubmit(driverForm);
    const response: AxiosResponse = yield call(api.drivers.addDriver, formData);
    yield put(addDriverSuccess(response.status));
    yield put(saveSuccess());
    yield call(getDrivers, getDriversAction(requestParams));
  } catch ({ response, ...rest }) {
    toast.error(response?.data?.message || '');
    yield put(addDriverError(response.status));
  }
}

export function* sendPhotoToAws(
  driverId: number,
  photo: TemporaryDeliveryPhoto,
) {
  const requirePhotoEndpointParams: LicensePhotoRequest = {
    driverId,
    format: photo.format,
    size: photo.file?.size,
  };
  const response: AxiosResponse<AwsLicensePhotoData> = yield call(
    api.drivers.requireLicensePhotoEndoint,
    requirePhotoEndpointParams,
  );
  yield call(api.delivery.sendPhotoToAws, response.data.putUrl, photo.file);
}

export function* updateDriver({
  payload: {
    driverForm: { photos, ...driverForm },
    requestParams,
  },
}: UpdateDriverAction) {
  try {
    // update driver
    const formData = formatExpireDateBeforeSubmit({
      ...driverForm,
      driverCompanyId: driverForm.driverCompanyId
        ? Number(driverForm.driverCompanyId)
        : undefined,
    });
    const response: AxiosResponse = yield call(
      api.drivers.updateDriver,
      formData,
    );
    // Update photos
    const driverId = driverForm.id ?? '';
    const temporaryPhotos = filter(photos, {
      temp: true,
    });
    yield all(
      map(temporaryPhotos, temporaryPhoto =>
        call(sendPhotoToAws, driverId, temporaryPhoto),
      ),
    );
    // call success actions and reload drivers list
    yield put(updateDriverSuccess(response.status));
    yield put(updateSuccess());
    yield call(getDrivers, getDriversAction(requestParams));
  } catch ({ response }) {
    toast.error(response?.data?.message || '');
    yield put(updateDriverError(response.status));
  }
}

export function* deleteDriver({
  payload: { requestParams, driverId },
}: DeleteDriverAction) {
  try {
    const response: AxiosResponse = yield call(
      api.drivers.deleteDriver,
      driverId,
    );
    yield put(deleteDriverSuccess(response.status));
    yield put(deleteSuccess());
    yield call(getDrivers, getDriversAction(requestParams));
  } catch ({ response }) {
    toast.error(response?.data?.message || '');
    yield put(deleteDriverError(response.status));
  }
}

export function* getState() {
  try {
    const response: AxiosResponse = yield call(api.drivers.getStates);
    yield put({ type: GET_STATES_SUCCESS, payload: response.data });
  } catch ({ response }) {
    toast.error(response?.data?.message || '');
    yield put({ type: GET_STATES_FAILURE });
  }
}

export function* getCompanies() {
  try {
    const response: AxiosResponse<Company[]> = yield call(
      api.delivery.getCompanies,
    );
    yield put(getCompaniesSuccess(response.data));
  } catch ({ response }) {
    toast.error(response?.data?.message || '');
    yield put(getCompaniesError(response.status));
  }
}

export function* getDriverStatus() {
  try {
    const response: AxiosResponse<GetDriverStatusResponse> = yield call(
      api.drivers.getStatus,
    );
    yield put(getDriverStatusSuccessAction(response.data));
  } catch (error) {
    yield put(getDriverStatusFailureAction(error));
  }
}

export default function* driversPageSaga() {
  yield takeLatest(DRIVERS_REQUEST, getDrivers);
  yield takeLatest(DRIVER_REQUEST, getDriver);
  yield takeLatest(ADD_DRIVER, addDriver);
  yield takeLatest(UPDATE_DRIVER, updateDriver);
  yield takeLatest(DELETE_DRIVER, deleteDriver);
  yield takeLatest(GET_STATES_REQUEST, getState);
  yield takeLatest(GET_COMPANIES_REQUEST, getCompanies);
  yield takeLatest(GET_DRIVER_STATUS_REQUEST, getDriverStatus);
}
