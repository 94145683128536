import {
  Coordinates,
  DeliveryLoadType,
  DeliveryStatus,
} from 'api/delivery/types';
import { DriverCompanyStatus } from 'api/driverCompanies/types';
import driverStatusEmptySvg from 'assets/images/driver_status_empty.svg';
import driverStatusFullSvg from 'assets/images/driver_status_full.svg';
import driverStatusHalfSvg from 'assets/images/driver_status_half.svg';
import driverStatusQuarterSvg from 'assets/images/driver_status_quarter.svg';
import driverStatusThreeQuarterSvg from 'assets/images/driver_status_three_quarter.svg';
import { RouteResult } from 'containers/DeliveriesPage/Map/types';
import forEach from 'lodash/forEach';
import split from 'lodash/split';

export const getRoute = (
  service: H.service.RoutingService,
  from: Coordinates,
  to: Coordinates,
) =>
  new Promise<RouteResult>((resolve, reject) => {
    const routingParameters = {
      mode: 'fastest;car',
      waypoint0: `geo!${from?.lat},${from?.lng}`,
      waypoint1: `geo!${to?.lat},${to?.lng}`,
      representation: 'display',
    };

    const onResult = (result: H.service.ServiceResult) => {
      if (result.response?.route) {
        const route = result.response.route[0];
        const routeShape = route.shape;
        const lineString = new H.geo.LineString();
        forEach(routeShape, point => {
          const parts = split(point, ',');
          // @ts-ignore
          lineString.pushLatLngAlt(parts[0], parts[1]);
        });
        const startPoint: Coordinates = {
          lat: route.waypoint[0].mappedPosition.latitude,
          lng: route.waypoint[0].mappedPosition.longitude,
        };
        const endPoint: Coordinates = {
          lat: route.waypoint[1].mappedPosition.latitude,
          lng: route.waypoint[1].mappedPosition.longitude,
        };
        resolve({
          lineString,
          startPoint,
          endPoint,
        });
      }
      reject(new Error('No routes found'));
    };

    service.calculateRoute(routingParameters, onResult, error =>
      reject(error.message),
    );
  });

export const getDriverLoadIcon = (actualLoad: DeliveryLoadType) => {
  switch (actualLoad) {
    case 'empty':
      return driverStatusEmptySvg;
    case 'half':
      return driverStatusHalfSvg;
    case 'quarter':
      return driverStatusQuarterSvg;
    case 'three-quarters':
      return driverStatusThreeQuarterSvg;
    default:
      return driverStatusFullSvg;
  }
};

export const actualLoadText = (actualStatus: DeliveryLoadType) => {
  switch (actualStatus) {
    case 'full':
      return 'Full';
    case 'half':
      return 'Half';
    case 'quarter':
      return '1/4';
    case 'three-quarters':
      return '3/4';
    case 'empty':
      return 'Empty';
    default:
      return null;
  }
};

export const getDriversStatusColor = (available: boolean) => {
  if (available) {
    return '#35A99A';
  }

  return '#B2B2B2';
};

export const getDeliveryStatusColor = (actualLoad: DeliveryStatus) => {
  switch (actualLoad) {
    case 'created':
      return '#D9D9D9';
    case 'driver requested':
      return '#FF7F00';
    case 'allocated':
      return '#35A99A';
    case 'arrived':
      return '#178CBE';
    case 'MOB':
      return '#9959D3';
    case 'to clear':
      return '#365377';
    case 'cleared':
      return '#B2B2B2';
    case 'invoiced':
      return '#80E08E';
    default:
      return '#E2373A';
  }
};

export const getDriverCompaniesStatusColor = (status: DriverCompanyStatus) => {
  switch (status) {
    case 'review':
      return '#FF7F00 ';
    case 'validate':
      return '#298478 ';
    case 'rejected':
      return '#C92A2A ';
    default:
      return '#B2B2B2';
  }
};

export const getDriverFullName = (driver: any) => {
  if (driver?.firstName && driver?.lastName) {
    return `${driver?.firstName} ${driver?.lastName}`;
  }
  if (driver?.firstName) {
    return `${driver?.firstName}`;
  }
  return 'Unknown Name';
};
