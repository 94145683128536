/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */

import { AxiosError } from 'axios';

Error.prototype.serverMessage = function() {
  const error = this as AxiosError;
  return error.isAxiosError
    ? error.response?.data.message
    : 'Sorry, try again later';
};
