import { Company } from 'api/delivery/types';
import {
  Driver,
  DriverDeleteRequest,
  DriverFormRequest,
  DriversPagination,
  DriversRequestParams,
  GetDriverStatusResponse,
} from 'api/drivers/types';
import { AxiosError } from 'axios';

import {
  ADD_DRIVER,
  ADD_DRIVER_FAILURE,
  ADD_DRIVER_SUCCESS,
  CLEAN_DRIVER,
  DELETE_DRIVER,
  DELETE_DRIVER_FAILURE,
  DELETE_DRIVER_SUCCESS,
  DRIVER_FAILURE,
  DRIVER_REQUEST,
  DRIVER_SUCCESS,
  DRIVERS_FAILURE,
  DRIVERS_REQUEST,
  DRIVERS_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_DRIVER_STATUS_FAILURE,
  GET_DRIVER_STATUS_REQUEST,
  GET_DRIVER_STATUS_SUCCESS,
  GET_STATES_REQUEST,
  UPDATE_DRIVER,
  UPDATE_DRIVER_FAILURE,
  UPDATE_DRIVER_SUCCESS,
} from './constants';
import { DriversActionTypes } from './types';

export const getDrivers = (
  params: DriversRequestParams,
): DriversActionTypes => ({
  type: DRIVERS_REQUEST,
  payload: params,
});

export const getDriversSuccess = (
  driversPagination: DriversPagination,
): DriversActionTypes => ({
  type: DRIVERS_SUCCESS,
  payload: driversPagination,
});

export const getDriversError = (error: number): DriversActionTypes => ({
  type: DRIVERS_FAILURE,
  payload: error,
});

export const getDriver = (id: string): DriversActionTypes => ({
  type: DRIVER_REQUEST,
  payload: id,
});

export const cleanDriver = (): DriversActionTypes => ({
  type: CLEAN_DRIVER,
});

export const getDriverSuccess = (driver: Driver): DriversActionTypes => ({
  type: DRIVER_SUCCESS,
  payload: driver,
});

export const getDriverError = (error: number): DriversActionTypes => ({
  type: DRIVER_FAILURE,
  payload: error,
});

export const addDriver = (
  driverForm: DriverFormRequest,
): DriversActionTypes => ({
  type: ADD_DRIVER,
  payload: driverForm,
});

export const addDriverSuccess = (status: number): DriversActionTypes => ({
  type: ADD_DRIVER_SUCCESS,
  payload: status,
});

export const addDriverError = (error: number): DriversActionTypes => ({
  type: ADD_DRIVER_FAILURE,
  payload: error,
});

export const updateDriver = (
  driverForm: DriverFormRequest,
): DriversActionTypes => ({
  type: UPDATE_DRIVER,
  payload: driverForm,
});

export const updateDriverSuccess = (status: number): DriversActionTypes => ({
  type: UPDATE_DRIVER_SUCCESS,
  payload: status,
});

export const updateDriverError = (error: number): DriversActionTypes => ({
  type: UPDATE_DRIVER_FAILURE,
  payload: error,
});

export const deleteDriver = (
  driverDeleteRequest: DriverDeleteRequest,
): DriversActionTypes => ({
  type: DELETE_DRIVER,
  payload: driverDeleteRequest,
});

export const deleteDriverSuccess = (status: number): DriversActionTypes => ({
  type: DELETE_DRIVER_SUCCESS,
  payload: status,
});

export const deleteDriverError = (error: number): DriversActionTypes => ({
  type: DELETE_DRIVER_FAILURE,
  payload: error,
});

export const getStates = (): DriversActionTypes => ({
  type: GET_STATES_REQUEST,
});

export const getCompanies = (): DriversActionTypes => ({
  type: GET_COMPANIES_REQUEST,
});

export const getCompaniesSuccess = (
  companies: Company[],
): DriversActionTypes => ({
  type: GET_COMPANIES_SUCCESS,
  payload: companies,
});

export const getCompaniesError = (error: number): DriversActionTypes => ({
  type: GET_COMPANIES_FAILURE,
  payload: error,
});

export const getDriverStatusRequestAction = (): DriversActionTypes => ({
  type: GET_DRIVER_STATUS_REQUEST,
});

export const getDriverStatusSuccessAction = (
  payload: GetDriverStatusResponse,
): DriversActionTypes => ({
  type: GET_DRIVER_STATUS_SUCCESS,
  payload,
});

export const getDriverStatusFailureAction = (
  payload: AxiosError,
): DriversActionTypes => ({
  type: GET_DRIVER_STATUS_FAILURE,
  payload,
});
