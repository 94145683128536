import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { initialState } from './reducer';

const selectDriverCompaniesPageStateDomain = (state: RootState) =>
  state.driverCompaniesPage || initialState;

const makeSelectDriverCompanies = () =>
  createSelector(
    selectDriverCompaniesPageStateDomain,
    ({ driverCompaniesPagination }) => driverCompaniesPagination,
  );

const makeSelectCompanyInsurance = () =>
  createSelector(
    selectDriverCompaniesPageStateDomain,
    ({ companyInsurance }) => companyInsurance,
  );

const makeSelectDriverCompanyPageNumber = () =>
  createSelector(
    selectDriverCompaniesPageStateDomain,
    ({ driverCompaniesPagination }) => driverCompaniesPagination.pageNumber,
  );

const makeSelectDrivers = () =>
  createSelector(
    selectDriverCompaniesPageStateDomain,
    ({ drivers }) => drivers,
  );

export default makeSelectDriverCompanies;

export {
  selectDriverCompaniesPageStateDomain,
  makeSelectCompanyInsurance,
  makeSelectDriverCompanyPageNumber,
  makeSelectDrivers,
};
