/**
 *
 * LoadingIndicator
 *
 */

import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface Props {
  style?: CSSProperties;
  className?: string;
  dataTestId?: string;
}

const LoadingIndicator: FC<Props> = ({ style, className, dataTestId }) => (
  <div
    style={style}
    className={classNames(
      'loading-indicator text-center font-weight-bold',
      className,
    )}
    data-testid={dataTestId}
  >
    <Spinner
      animation="border"
      size="sm"
      className="loading-indicator-spinner"
    />
    <FormattedMessage id="components.loadingIndicator.loading" />
    ...
  </div>
);

export default LoadingIndicator;
