import React, { FC } from 'react';

interface Props {
  title: string;
  body: string;
}

const ToastMessage: FC<Props> = ({ title, body }) => (
  <div>
    <div className="toast-title">{title}</div>
    <div className="toast-message">{body}</div>
  </div>
);

export default ToastMessage;
