import rootAxios, { AxiosTransformer } from 'axios';
import { LodashCamelCase, LodashSnakeCase } from 'lodash/fp';
import camelCase from 'lodash/fp/camelCase';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import isObjectLike from 'lodash/fp/isObjectLike';
import isPlainObject from 'lodash/fp/isPlainObject';
import map from 'lodash/fp/map';
import snakeCase from 'lodash/fp/snakeCase';
import set from 'lodash/set';
import transform from 'lodash/transform';

interface CreateIterateeType {
  (converter: LodashCamelCase | LodashSnakeCase, self: any): any;
}

interface CurryCaseType {
  (keyConverter: LodashCamelCase | LodashSnakeCase):
    | LodashCamelCase
    | LodashSnakeCase;
}

const createIteratee: CreateIterateeType = (converter, self) => (
  result: any,
  value: any,
  key: string,
): any =>
  set(result, converter(key), isObjectLike(value) ? self(value) : value);

const curryCase: CurryCaseType = keyConverter =>
  function node(element: any): any {
    if (isArray(element)) return map(node, element);
    if (isPlainObject(element))
      return transform(element, createIteratee(keyConverter, node));
    return element;
  };

const mapToCamelCase = curryCase(camelCase);
const mapToSnakeCase = curryCase(snakeCase);

const requestToSnakeCase: AxiosTransformer = (request, header) => {
  if (header.disableTransform) {
    delete header.disableTransform;
    return request;
  }
  return mapToSnakeCase(request);
};

const responseToCamelCase: AxiosTransformer = response => {
  if (isEmpty(response)) return {};
  return mapToCamelCase(JSON.parse(response));
};

const createAxiosInstance = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const axios = rootAxios.create({
    baseURL,
  });
  return axios;
};

export { requestToSnakeCase, responseToCamelCase, createAxiosInstance };
