/**
 *
 * DeliveriesPage selectors
 *
 */

import { Company } from 'api/delivery/types';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { initialState } from './reducer';

/**
 * Direct selector to the deliveriesPage state domain
 */

const selectDeliveriesPageStateDomain = (state: RootState) =>
  state.deliveriesPage || initialState;

/**
 * Other specific selectors
 */

const makeSelectLoading = () =>
  createSelector(selectDeliveriesPageStateDomain, ({ loading }) => loading);

const makeSelectDeliveries = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveriesPagination }) => deliveriesPagination,
  );

const makeSelectDrivers = () =>
  createSelector(selectDeliveriesPageStateDomain, ({ drivers }) => drivers);

const makeSelectSelectedDelivery = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ selectedDelivery }) => selectedDelivery,
  );

const makeSelectDeliveryDetailPrice = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryDetail }) =>
      deliveryDetail?.price ?? {
        promoCodeUsed: '',
        discountType: '',
        discountValue: 0,
        discountValueSaved: 0,
        extraTime: 0,
        extraTimeValue: 0,
        loadTime: 0,
        loadValue: 0,
        ride: 0,
        tip: 0,
        total: 0,
        unloadTime: 0,
        unloadValue: 0,
      },
  );

const makeSelectDeliveryDetail = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryDetail }) => deliveryDetail,
  );

const makeSelectDeliveryDetailStatus = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryDetail }) => deliveryDetail?.status,
  );

const makeSelectIsAssigningDriver = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ isAssigningDriver }) => isAssigningDriver,
  );

const makeSelectDriverDetails = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ driverDetails }) => driverDetails,
  );

const makeSelectLoadingDriverDetail = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ loadingDriverDetail }) => loadingDriverDetail,
  );

const makeSelectDeliveryFormValues = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryFormValues }) => deliveryFormValues,
  );

const makeSelectLoadingDeliveryDetail = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ loadingDeliveryDetail }) => loadingDeliveryDetail,
  );

const makeSelectDeliveryDetailId = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryDetail }) => deliveryDetail?.id,
  );

const makeSelectDeliveryDetailDriver = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryDetail }) => deliveryDetail?.driver,
  );

const makeSelectShouldShowDeliveryDetailModal = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ shouldShowDeliveryDetailModal }) => shouldShowDeliveryDetailModal,
  );

const makeSelectIsSubmittingDeliveryDetail = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ isSubmittingDeliveryDetail }) => isSubmittingDeliveryDetail,
  );

const makeSelectUpdateDeliveryError = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ updateDeliveryError }) => updateDeliveryError,
  );

const makeSelectDeliveryQueryType = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryQueryType }) => deliveryQueryType,
  );

const makeSelectDeliveryListPage = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveryListPage }) => deliveryListPage,
  );

/**
 * Selector for company list
 * */
const makeSelectCompanies = () =>
  createSelector(selectDeliveriesPageStateDomain, ({ companies }) => companies);

/**
 * Selector for company list in options format
 * */
const makeSelectCompaniesOptions = () =>
  createSelector(makeSelectCompanies(), (companies: Company[]) =>
    map(companies, company => ({
      label: capitalize(company.name),
      value: company.id,
    })),
  );

const makeSelectLoadingGeneratingReport = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ loadingGeneratingReport }) => loadingGeneratingReport,
  );

const makeSelectErrorGeneratingReport = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ errorGeneratingReport }) => errorGeneratingReport,
  );

const makeSelectIsOpenReportPopup = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ isOpenReportPopup }) => isOpenReportPopup,
  );

const makeSelectDeliveriesFilter = () =>
  createSelector(
    selectDeliveriesPageStateDomain,
    ({ deliveriesFilter }) => deliveriesFilter,
  );

const makeSelectQuotes = () =>
  createSelector(selectDeliveriesPageStateDomain, ({ quotes }) => quotes);

/**
 * Default selector used by DeliveriesPage
 */

const makeSelectDeliveriesState = () =>
  createSelector(selectDeliveriesPageStateDomain, subState => subState);

export default makeSelectDeliveriesState;
export {
  selectDeliveriesPageStateDomain,
  makeSelectLoading,
  makeSelectDeliveries,
  makeSelectDrivers,
  makeSelectSelectedDelivery,
  makeSelectDriverDetails,
  makeSelectDeliveryFormValues,
  makeSelectLoadingDeliveryDetail,
  makeSelectDeliveryDetailId,
  makeSelectShouldShowDeliveryDetailModal,
  makeSelectIsSubmittingDeliveryDetail,
  makeSelectUpdateDeliveryError,
  makeSelectDeliveryQueryType,
  makeSelectDeliveryListPage,
  makeSelectLoadingDriverDetail,
  makeSelectCompanies,
  makeSelectCompaniesOptions,
  makeSelectLoadingGeneratingReport,
  makeSelectErrorGeneratingReport,
  makeSelectIsOpenReportPopup,
  makeSelectIsAssigningDriver,
  makeSelectDeliveriesFilter,
  makeSelectDeliveryDetailDriver,
  makeSelectDeliveryDetailPrice,
  makeSelectQuotes,
  makeSelectDeliveryDetail,
  makeSelectDeliveryDetailStatus,
};
