/* eslint-disable react/jsx-props-no-spreading */
/**
 *
 * PrivateRoute
 *
 */

import Template from 'containers/Template';
import React, { FC } from 'react';
import { Redirect, Route } from 'react-router';

interface Props {
  path: string;
  component: (props: any) => JSX.Element;
  exact?: boolean;
  isAuthenticated: boolean;
  redirectPath: string;
}

const PrivateRoute: FC<Props> = ({
  path,
  component: Component,
  exact,
  isAuthenticated,
  redirectPath,
}) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      isAuthenticated ? (
        <Template>
          <Component {...props} />
        </Template>
      ) : (
        <Redirect to={redirectPath} />
      )
    }
  />
);

export default PrivateRoute;
