import { createAxiosInstance } from 'utils/network';

import { configInterceptors } from './interceptor';

const axios = createAxiosInstance();

configInterceptors(axios);

export { axios };
export { default as auth } from './auth';
export { default as delivery } from './delivery';
export { default as drivers } from './drivers';
export { default as clients } from './clients';
export { default as driverCompanies } from './driverCompanies';
export { default as driverDetails } from './driverDetails';
export { default as fcm } from './fcm';
export { default as backend } from './backend';
export { default as quotes } from './quotes';
