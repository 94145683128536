import {
  CLEAR_MESSAGE,
  DELETE_SUCCESS,
  SAVE_SUCCESS,
  UPDATE_SUCCESS,
} from 'containers/FeedbackMessage/constants';

import { FeedbackMessageActionTypes } from './types';

export const saveSuccess = (): FeedbackMessageActionTypes => ({
  type: SAVE_SUCCESS,
});

export const updateSuccess = (): FeedbackMessageActionTypes => ({
  type: UPDATE_SUCCESS,
});

export const deleteSuccess = (): FeedbackMessageActionTypes => ({
  type: DELETE_SUCCESS,
});

export const clearMessage = (): FeedbackMessageActionTypes => ({
  type: CLEAR_MESSAGE,
});
