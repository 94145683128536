export const DRIVER_COMPANIES_PAGE_SIZE = 9;

export const GET_DRIVER_COMPANIES_REQUEST =
  'DriverCompaniesPage/GET_DRIVER_COMPANIES_REQUEST';
export const GET_DRIVER_COMPANIES_SUCCESS =
  'DriverCompaniesPage/GET_DRIVER_COMPANIES_SUCCESS';
export const GET_DRIVER_COMPANIES_FAILURE =
  'DriverCompaniesPage/GET_DRIVER_COMPANIES_FAILURE';

export const UPDATE_DRIVER_COMPANY_REQUEST =
  'DriverCompanyPage/UPDATE_DRIVER_COMPANY_REQUEST';
export const UPDATE_DRIVER_COMPANY_SUCCESS =
  'DriverCompanyPage/UPDATE_DRIVER_COMPANY_SUCCESS';
export const UPDATE_DRIVER_COMPANY_FAILURE =
  'DriverCompanyPage/UPDATE_DRIVER_COMPANY_FAILURE';

export const GET_COMPANY_INSURANCE_REQUEST =
  'DriverCompaniesPage/GET_COMPANY_INSURANCE_REQUEST';
export const GET_COMPANY_INSURANCE_SUCCESS =
  'DriverCompaniesPage/GET_COMPANY_INSURANCE_SUCCESS';
export const GET_COMPANY_INSURANCE_FAILURE =
  'DriverCompaniesPage/GET_COMPANY_INSURANCE_FAILURE';

export const UPDATE_COMPANY_INSURANCE_REQUEST =
  'DriverCompanyPage/UPDATE_COMPANY_INSURANCE_REQUEST';
export const UPDATE_COMPANY_INSURANCE_SUCCESS =
  'DriverCompanyPage/UPDATE_COMPANY_INSURANCE_SUCCESS';
export const UPDATE_COMPANY_INSURANCE_FAILURE =
  'DriverCompanyPage/UPDATE_COMPANY_INSURANCE_FAILURE';

export const GET_DRIVERS_REQUEST = 'DriverCompanyPage/GET_DRIVERS_REQUEST';
export const GET_DRIVERS_SUCCESS = 'DriverCompanyPage/GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_FAILURE = 'DriverCompanyPage/GET_DRIVERS_FAILURE';
