/**
 * FeedbackMessage Messages
 *
 * This contains all the text for the FeedbackMessage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'containers.feedbackMessage';

const messages = defineMessages({
  saveSuccess: {
    id: `${scope}.saveSuccess`,
  },
  updateSuccess: {
    id: `${scope}.updateSuccess`,
  },
  deleteSuccess: {
    id: `${scope}.deleteSuccess`,
  },
});

export default messages;
