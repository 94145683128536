/**
 *
 * DeliveriesPage reducer
 *
 */

import produce, { Draft } from 'immer';

import { TOGGLE_SIDE_MENU } from './constants';
import { TemplateActionTypes, TemplateState } from './types';

export const initialState: TemplateState = {
  sideMenuOpened: true,
};

const templateReducer = produce(
  (draft: Draft<TemplateState>, action: TemplateActionTypes) => {
    switch (action.type) {
      case TOGGLE_SIDE_MENU:
        draft.sideMenuOpened = !draft.sideMenuOpened;
        break;
      default:
    }
  },
  initialState,
);

export default templateReducer;
