/**
 * SideMenu Messages
 *
 * This contains all the text for the SideMenu container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'components.sideMenu';

const messages = defineMessages({
  manageDeliveries: {
    id: `${scope}.manageDeliveries`,
  },
  listDeliveries: {
    id: `${scope}.listDeliveries`,
  },
  listDrivers: {
    id: `${scope}.listDrivers`,
  },
  listClients: {
    id: `${scope}.listClients`,
  },
  listDriverCompanies: {
    id: `${scope}.listDriverCompanies`,
  },
  logout: {
    id: `${scope}.logout`,
  },
});

export default messages;
