import {
  ClientPagination,
  ClientRequestParams,
  UpdateClientRequest,
} from 'api/clients/types';
import { AxiosError } from 'axios';

import {
  CLIENTS_FAILURE,
  CLIENTS_REQUEST,
  CLIENTS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
} from './constants';
import { ClientsActionTypes } from './types';

export const getClientsRequest = (
  payload: ClientRequestParams,
): ClientsActionTypes => ({
  type: CLIENTS_REQUEST,
  payload,
});

export const getClientsSuccess = (
  payload: ClientPagination,
): ClientsActionTypes => ({
  type: CLIENTS_SUCCESS,
  payload,
});

export const getClientsFailure = (payload: number): ClientsActionTypes => ({
  type: CLIENTS_FAILURE,
  payload,
});

export const updateClient = (
  payload: UpdateClientRequest,
): ClientsActionTypes => ({
  type: UPDATE_CLIENT_REQUEST,
  payload,
});

export const updateClientSuccess = (): ClientsActionTypes => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: undefined,
});

export const updateClientFailure = (
  payload: AxiosError,
): ClientsActionTypes => ({
  type: UPDATE_CLIENT_FAILURE,
  payload,
});
