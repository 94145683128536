export default {
  components: {
    loadingIndicator: {
      loading: 'Loading',
    },
    sideMenu: {
      yourProfile: 'Your profile',
      manageDeliveries: 'Manage deliveries',
      listDeliveries: 'List of deliveries',
      listDrivers: 'List of drivers',
      listClients: 'List of clients',
      listDriverCompanies: 'Owners companies',
      logout: 'Logout',
    },
    driversCount: {
      countMessage: 'Total number of drivers: ',
    },
    clientsCount: {
      countMessage: 'Total number of clients: ',
    },
    driverCompaniesCount: {
      countMessage: 'Total owners companies: ',
    },
    search: {
      field: 'Search',
    },
    driversFilter: {
      options: {
        all: 'Select status',
        active: 'Active',
        unActive: 'Inactive',
      },
    },
    driversTypeFilter: {
      options: {
        all: 'Select type',
        owner: 'Owner',
        driver: 'Driver',
      },
    },
    companiesFilter: {
      options: {
        all: 'Select status',
        review: 'Review',
        validate: 'Validate',
        rejected: 'Rejected',
      },
    },
    driversTable: {
      empty: 'Empty',
      half: 'Half',
      quarter: '1/4',
      threeQuarters: '3/4',
      full: 'full',
      active: 'Active',
      unactive: 'Inactive',
      driverId: 'Driver id',
      type: 'Type',
      name: 'Name',
      numberPlate: 'Numberplate',
      typeOfCar: 'Type of car',
      brand: 'Brand',
      contactNumber: 'Contact number',
      color: 'Color',
      gender: 'Gender',
      vehicleSize: 'Vehicle size',
      actualLoad: 'Actual load',
      status: 'Status',
      owner: 'Owner',
      driver: 'Driver',
    },
    driversForm: {
      addDriver: 'Add Driver',
      closeModal: 'Close',
      saveButton: 'Save',
      modifyButton: 'Modify',
      cancelButton: 'Cancel',
      wait: 'Wait',
      account: 'Account',
      email: 'Email',
      emailInvalid: 'Email invalid',
      password: 'Password',
      verifyPassword: 'Confirm password',
      accountStatus: 'Account status',
      personalInformation: 'Personal information',
      firstName: 'Name',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      gender: 'Gender',
      phoneNumberInvalid: 'Phone number invalid',
      male: 'Male',
      female: 'Female',
      carInformation: 'Car information',
      typeOfCar: 'Type of car',
      numberplate: 'Numberplate',
      color: 'Color',
      brand: 'Brand',
      model: 'Model',
      availableSize: 'Available size',
      required: 'Required field',
      passwordMinLength: 'Your password is too short',
      passwordMaxLength: 'Your password is too long',
      passwordNotMatching: 'Password not matching',
      invalidPhone: 'Invalid phone number',
      licenseInformation: "Driver's license",
      licenseNumber: 'License Number',
      licenseNumberMaxDigits: 'License Number must be at most 25 characters',
      licenseState: 'State',
      licenseExpireDate: 'Expiry Date',
      licenseImage: 'Image of license',
      invalidLicenseExpireDate: 'Invalid date',
      licensePhotoFieldPlaceholder: "Driver's license",
      licensePhotoValidationTextFiles:
        'Max: 25MB. Files accepted: JPEG, PNG and PDF',
      licensePhotoValidationTextSize: 'Max: 25MB (optional)',
      licensePhotoDescriptionNoVideo: 'Only 1 photo at a time. No videos.',
      company: 'Company',
      selectACompany: 'Select a company',
    },
    reportForm: {
      reportTitle: 'Generate Report',
      generateReport: 'Generate',
      generatingReport: 'Generating...',
      dateLabel: 'Date',
      from: 'Date Begin',
      to: 'Date End',
      filters: 'Filters',
      companies: 'Companies',
      required: 'Required field',
      closeModal: 'Close',
      allFilters: 'All Filters',
      minItemValidation: 'Please select at least 1 item',
      tryAgain: 'Try again',
    },
    clientsTable: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phoneNumber: 'Phone number',
      notifications: 'Notifications',
      on: 'On',
      off: 'Off',
      creationDate: 'Creation date',
    },
    driverCompaniesTable: {
      companyId: 'Company ID',
      name: 'Name',
      taxId: 'Tax ID',
      phoneNumber: 'Phone number',
      website: 'Website',
      country: 'Country',
      state: 'State',
      city: 'City',
      address: 'Address',
      owner: 'Owner',
      status: 'Status',
      review: 'Review',
      validate: 'Validate',
      rejected: 'Rejected',
    },
    driverCompaniesForm: {
      header: 'Company',
      closeModal: 'Close',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      wait: 'Wait',
      companyStatus: 'Company status',
      review: 'To be reviewed',
      validate: 'Validate',
      rejected: 'Rejected',
      name: 'Name',
      taxId: 'Tax ID',
      phoneNumber: 'Phone number',
      invalidPhone: 'Invalid phone number',
      website: 'Website',
      country: 'Country',
      state: 'State',
      city: 'City',
      address: 'Address',
      addressPlaceholder: 'Street name, number, ...',
      autoInsurance: 'Commercial auto insurance',
      insuranceName: 'Insurance name',
      insuranceNamePlaceholder: 'Name of your insurance',
      certificateNumber: 'Certificate number',
      expirationDate: 'Next expiration date',
      certificateImage: 'Insurance certificate',
      photoValidationTextFiles: 'Max: 25MB. Files accepted: JPEG and PNG',
      ownerInformation: 'Owner information',
      ownerName: 'Owner name',
      email: 'Email',
      required: 'Required field',
    },
    deleteDriverModal: {
      deleteQuestion: 'Are you sure you want to delete this driver?',
      yes: 'Yes',
      no: 'No',
    },
    clientsForm: {
      header: 'Edit client info',
      closeModal: 'Close modal',
      createdAt: 'Creation date',
      email: 'E-mail',
      changePassword: 'Change password',
      confirmChangePassword: 'Confirm change password',
      firstName: 'First name',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      btnCancel: 'Cancel',
      btnModify: 'Modify',
      passwordMinLength: 'You password needs to be minimum 8 characters',
      passwordMaxLength: 'Your password is too long',
      passwordNotMatching: 'Passwords does not match',
      passwordRequired: 'Please enter password',
    },
    popoverFilter: {
      clearDateRange: 'clear',
      inputSearchPlaceholder: 'Search',
      selectAll: 'Select All',
    },
  },
  containers: {
    notFoundPage: {
      header: 'Page not found',
    },
    homePage: {
      header: 'Project Template React',
      logout: 'Logout',
    },
    signInPage: {
      header: 'This is the SignInPage component!',
      email: 'E-mail',
      password: 'Password',
      submit: 'Login',
      waiting: 'Please, waiting...',
      emailInvalid: 'Please enter valid email',
      emailRequired: 'Please enter your email',
      passwordRequired: 'Please enter password',
      passwordMinLength: 'Your password is too short',
      passwordMaxLength: 'Your password is too long',
    },
    userDetail: {
      name: 'Name',
      email: 'E-mail',
    },
    forgotPasswordPage: {
      password: 'New password',
      confirmPassword: 'Confirm password',
      submit: 'Submit',
      waiting: 'Please, waiting...',
      newPasswordRequired: 'Please enter the new password',
      confirmPasswordRequired: 'Please enter the confirm password',
      passwordMinLength: 'You password needs to be minimum 8 characters',
      passwordMaxLength: 'Your password is too long',
      passwordsNotMatch: 'Passwords does not match',
    },
    deliveriesPage: {
      reference: 'Reference',
      client: 'Client',
      created: 'Creation date',
      driver: 'Driver',
      dateTime: 'Delivery date',
      from: 'From',
      to: 'To',
      price: 'Price',
      tip: 'Tip',
      loadNeeded: 'Load Needed',
      requestedLoad: 'Request load',
      driverClaimedLoad: 'Driver claimed load',
      wrongLoad:
        "Load capacity requested by client doesn't match capacity claimed by driver",
      status: 'Status',
      pickupInstructions: 'Pickup instructions',
      deliveryInstructions: 'Delivery instructions',
      cancel: 'Cancel',
      save: 'Save',
      selectDriver: 'Select a driver',
      pickupAddress: 'From (pick up address)',
      deliveryAddress: 'To (delivery address)',
      pickupTime: 'Pickup time',
      truck: 'truck',
      delivery: 'Delivery',
      totalPriceOfTheDelivery: 'Total price of the delivery',
      detailsOfThePrice: 'Details of the price',
      ride: 'Ride: ',
      unloadTime: 'Unload time: ',
      loadTime: 'Load time: ',
      discount: 'Discount: ',
      for: ' for ',
      minutes: ' minutes',
      loading: 'Loading...',
      notes: 'Notes',
      notesPlaceholder: 'Leave notes to record info about a delivery...',
    },
    driversPage: {
      addDriver: 'Add Driver',
      search: 'Name, last name, or email',
    },
    clientsPage: {
      header: 'This is the ClientsPage component!',
      search: 'Name, last name, or email',
    },
    driverCompaniesPage: {
      header: 'This is the CompaniesPage component!',
    },
    feedbackMessage: {
      saveSuccess: 'Item saved successfully',
      updateSuccess: 'Item updated successfully',
      deleteSuccess: 'Item deleted successfully',
    },
  },
};
