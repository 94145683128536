/**
 *
 * DeliveriesPage reducer
 *
 */

import produce, { Draft } from 'immer';
import map from 'lodash/map';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'utils/constants';
import { getDriverFullName } from 'utils/map';

import {
  ASSIGN_DRIVER_FAILURE,
  ASSIGN_DRIVER_REQUEST,
  ASSIGN_DRIVER_SUCCESS,
  CLEAR_QUOTES_DATA,
  CLEAR_REPORT_ERROR,
  DRIVER_DETAILS_FAILURE,
  DRIVER_DETAILS_REQUEST,
  DRIVER_DETAILS_SUCCESS,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_SUCCESS,
  GET_DELIVERIES_FAILURE,
  GET_DELIVERIES_REQUEST,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERY_DETAIL_FAILURE,
  GET_DELIVERY_DETAIL_REQUEST,
  GET_DELIVERY_DETAIL_SUCCESS,
  GET_DRIVERS_SUCCESS,
  GET_QUOTES_FAILURE,
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  HIDE_DELIVERY_DETAIL_MODAL,
  SEARCH_CLIENTS_FAILURE,
  SEARCH_CLIENTS_REQUEST,
  SEARCH_CLIENTS_SUCCESS,
  SEARCH_DRIVERS_FAILURE,
  SEARCH_DRIVERS_REQUEST,
  SEARCH_DRIVERS_SUCCESS,
  SELECT_DELIVERY,
  SET_DELIVERY_LIST_PAGE,
  SET_DELIVERY_QUERY_TYPE,
  SET_IS_OPEN_REPORT_POPUP,
  SHOW_DELIVERY_DETAIL_MODAL,
  UPDATE_DELIVERY_FAILURE,
  UPDATE_DELIVERY_REQUEST,
  UPDATE_DELIVERY_SUCCESS,
} from './constants';
import { DeliveriesActionTypes, DeliveriesState } from './types';

export const initialState: DeliveriesState = {
  deliveriesFilter: {
    drivers: {
      loading: false,
      data: [],
    },
    clients: {
      loading: false,
      data: [],
    },
  },
  deliveryQueryType: 'All',
  deliveryListPage: 1,
  deliveriesPagination: {
    totalCount: 0,
    pageNumber: 0,
    totalPages: 1,
    data: [],
  },
  drivers: [],
  loading: true,
  hasError: false,
  selectedDelivery: undefined,
  deliveryDetail: undefined,
  deliveryFormValues: {
    toCollect: '',
    toDeliver: '',
    status: 'created',
    photos: [],
    pickupAddress: '',
    deliveryAddress: '',
    deliveryTime: new Date(),
    cost: 0,
    creationDate: '',
    client: '',
    loadNeeded: 'half',
    driverId: undefined,
    notes: '',
  },
  loadingDriverDetail: false,
  loadingDeliveryDetail: false,
  isSubmittingDeliveryDetail: false,
  shouldShowDeliveryDetailModal: false,
  driverDetails: undefined,
  isAssigningDriver: false,
  companies: [],
  loadingGeneratingReport: false,
  errorGeneratingReport: false,
  isOpenReportPopup: false,
  quotes: {
    loading: false,
    data: undefined,
  },
};

const deliveriesPageReducer = produce(
  (draft: Draft<DeliveriesState>, action: DeliveriesActionTypes) => {
    switch (action.type) {
      case GET_DELIVERIES_REQUEST:
        draft.loading = true;
        break;
      case GET_DELIVERIES_SUCCESS:
        draft.deliveriesPagination = action.payload;
        if (!draft.selectedDelivery) {
          const [first] = action.payload.data;
          draft.selectedDelivery = first;
        }
        draft.loading = false;
        draft.hasError = false;
        break;
      case GET_DELIVERIES_FAILURE:
        draft.loading = false;
        draft.hasError = true;
        break;
      case GET_DRIVERS_SUCCESS:
        draft.drivers = action.payload;
        break;
      case SELECT_DELIVERY:
        draft.selectedDelivery = action.payload;
        break;
      case GET_DELIVERY_DETAIL_REQUEST:
        draft.loadingDeliveryDetail = true;
        break;
      case GET_DELIVERY_DETAIL_SUCCESS:
        draft.deliveryDetail = action.payload;

        draft.deliveryFormValues.driverId = action.payload.driver?.id;
        draft.deliveryFormValues.pickupAddress =
          action.payload.pickupAddress.secondLine;
        draft.deliveryFormValues.deliveryAddress =
          action.payload.deliveryAddress.secondLine;
        draft.deliveryFormValues.cost = Number(action.payload.cost);
        draft.deliveryFormValues.client = getDriverFullName(
          action.payload.client,
        );
        draft.deliveryFormValues.loadNeeded = action.payload.loadNeeded;

        draft.deliveryFormValues.creationDate = moment
          .utc(action.payload.createdAt)
          .tz(DEFAULT_TIMEZONE)
          .format('YYYY-MM-DD h[h]mma');
        draft.deliveryFormValues.deliveryTime = new Date(
          action.payload.deliveryTime,
        );

        draft.deliveryFormValues.notes = action.payload.notes;
        draft.deliveryFormValues.toCollect =
          action.payload.instructions.toCollect;
        draft.deliveryFormValues.toDeliver =
          action.payload.instructions.toDeliver;
        draft.deliveryFormValues.status = action.payload.status;
        draft.deliveryFormValues.photos = map(action.payload.photos, photo => ({
          ...photo,
          temp: false,
        }));
        draft.loadingDeliveryDetail = false;
        break;
      case GET_DELIVERY_DETAIL_FAILURE:
        draft.loadingDeliveryDetail = false;
        break;
      case UPDATE_DELIVERY_REQUEST:
        draft.isSubmittingDeliveryDetail = true;
        break;
      case UPDATE_DELIVERY_SUCCESS:
        draft.isSubmittingDeliveryDetail = false;
        draft.shouldShowDeliveryDetailModal = false;
        break;
      case UPDATE_DELIVERY_FAILURE:
        draft.isSubmittingDeliveryDetail = false;
        draft.updateDeliveryError = action.payload;
        break;
      case SHOW_DELIVERY_DETAIL_MODAL:
        draft.shouldShowDeliveryDetailModal = true;
        break;
      case HIDE_DELIVERY_DETAIL_MODAL:
        draft.shouldShowDeliveryDetailModal = false;
        draft.deliveryFormValues = initialState.deliveryFormValues;
        draft.updateDeliveryError = undefined;
        break;
      case DRIVER_DETAILS_REQUEST:
        draft.loadingDriverDetail = true;
        break;
      case DRIVER_DETAILS_SUCCESS:
        draft.loadingDriverDetail = false;
        draft.driverDetails = action.payload;
        break;
      case DRIVER_DETAILS_FAILURE:
        draft.hasError = true;
        draft.loadingDriverDetail = false;
        break;
      case ASSIGN_DRIVER_REQUEST:
        draft.isAssigningDriver = true;
        break;
      case ASSIGN_DRIVER_SUCCESS:
      case ASSIGN_DRIVER_FAILURE:
        draft.isAssigningDriver = false;
        break;
      case SET_DELIVERY_QUERY_TYPE:
        draft.deliveryQueryType = action.payload;
        break;
      case SET_DELIVERY_LIST_PAGE:
        draft.deliveryListPage = action.payload;
        break;
      case GET_COMPANIES_SUCCESS:
        draft.companies = action.payload;
        break;
      case GET_COMPANIES_FAILURE:
        draft.hasError = true;
        break;
      case GENERATE_REPORT_REQUEST:
        draft.loadingGeneratingReport = true;
        draft.errorGeneratingReport = false;
        break;
      case GENERATE_REPORT_SUCCESS:
        draft.loadingGeneratingReport = false;
        draft.isOpenReportPopup = false;
        break;
      case GENERATE_REPORT_FAILURE:
        draft.loadingGeneratingReport = false;
        draft.errorGeneratingReport = true;
        break;
      case CLEAR_REPORT_ERROR:
        draft.loadingGeneratingReport = false;
        draft.errorGeneratingReport = false;
        break;
      case SET_IS_OPEN_REPORT_POPUP:
        draft.isOpenReportPopup = action.payload;
        break;
      case SEARCH_DRIVERS_REQUEST:
        draft.deliveriesFilter.drivers.loading = true;
        break;
      case SEARCH_DRIVERS_SUCCESS:
        draft.deliveriesFilter.drivers.loading = false;
        draft.deliveriesFilter.drivers.data = action.payload;
        break;
      case SEARCH_DRIVERS_FAILURE:
        draft.deliveriesFilter.drivers.loading = false;
        draft.deliveriesFilter.drivers.data = [];
        break;
      case SEARCH_CLIENTS_REQUEST:
        draft.deliveriesFilter.clients.loading = true;
        break;
      case SEARCH_CLIENTS_SUCCESS:
        draft.deliveriesFilter.clients.loading = false;
        draft.deliveriesFilter.clients.data = action.payload;
        break;
      case SEARCH_CLIENTS_FAILURE:
        draft.deliveriesFilter.clients.loading = false;
        draft.deliveriesFilter.clients.data = [];
        break;
      case GET_QUOTES_REQUEST:
        draft.quotes.loading = true;
        break;
      case GET_QUOTES_SUCCESS:
        draft.quotes.loading = false;
        draft.quotes.data = action.payload;
        break;
      case GET_QUOTES_FAILURE:
        draft.quotes.loading = false;
        break;
      case CLEAR_QUOTES_DATA:
        draft.quotes = initialState.quotes;
        break;
      default:
    }
  },
  initialState,
);

export default deliveriesPageReducer;
