import * as api from 'api';
import { GetBackendVersionResponse } from 'api/backend/types';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getBackendVersionFailure, getBackendVersionSuccess } from './actions';
import { GET_BACKEND_VERSION_REQUEST } from './constants';

function* getBackendVersion() {
  try {
    const response: AxiosResponse<GetBackendVersionResponse> = yield call(
      api.backend.getVersion,
    );

    yield put(getBackendVersionSuccess(response.data.apiVersion));
  } catch (error) {
    yield put(getBackendVersionFailure());
  }
}

export default function* appSaga() {
  yield takeLatest(GET_BACKEND_VERSION_REQUEST, getBackendVersion);
}
