import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { logoutUser, updateToken } from 'containers/App/actions';
import { selectAppDomain } from 'containers/App/selectors';
import { selectLanguageProviderDomain } from 'containers/LanguageProvider/selectors';
import { store } from 'store';
import { createAxiosInstance } from 'utils/network';

import { RefreshTokenResponse } from './auth/types';

const refreshClient = createAxiosInstance();

export const configInterceptors = (axios: AxiosInstance) => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    // @ts-ignore
    const { locale } = selectLanguageProviderDomain(store.getState());
    // @ts-ignore
    const { token } = selectAppDomain(store.getState());

    if (locale) {
      config.headers.common['Accept-Language'] = locale;
    }

    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    response =>
      // Do something with response data
      response,
    async error => {
      const { config: originalReq, response } = error;

      if (
        !originalReq?.isRetryAttempt &&
        response?.status === 401 &&
        response?.data.message === 'jwt expired'
      ) {
        try {
          // @ts-ignore
          const { refreshToken } = selectAppDomain(store.getState());
          const tokenResponse = await refreshClient.post<RefreshTokenResponse>(
            '/auth/refresh-token',
            { refreshToken },
          );
          const token = tokenResponse.data.authToken;
          store.dispatch(updateToken(token));
          originalReq.isRetryAttempt = true;
          originalReq.headers.Authorization = `Bearer ${token}`;
          return await axios.request(originalReq);
        } catch (e) {
          if (e.response?.status === 401) {
            store.dispatch(logoutUser());
          }
          return Promise.reject(e);
        }
      }

      if (
        !originalReq?.isRetryAttempt &&
        response?.status === 401 &&
        (response?.data.message === 'accessToken revoked' ||
          response?.data.message === 'refreshToken revoked')
      ) {
        store.dispatch(logoutUser());
      }

      return Promise.reject(error);
    },
  );
};
