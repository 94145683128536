/**
 *
 * App
 *
 */

import RoutesProvider from 'containers/RoutesProvider';
import React, { FC } from 'react';

import { useFirebase } from './hooks';
import { AppProps } from './types';

const App: FC<AppProps> = () => {
  useFirebase();
  return <RoutesProvider />;
};

export default App;
