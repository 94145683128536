/**
 *
 * SignInPage saga
 *
 */

import * as api from 'api';
import { push } from 'connected-react-router';
import { paths } from 'containers/RoutesProvider/routes';
import { SIGN_IN_USER_SUCCESS } from 'containers/SignInPage/constants';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import { SignInSuccessAction } from '../SignInPage/types';
import {
  clearTokens,
  loginUser,
  loginUserSuccess,
  sendFCMTokenFailure,
  sendFCMTokenSuccess,
} from './actions';
import { LOGOUT, SEND_FCM_TOKEN_REQUEST } from './constants';
import { SendFCMTokenRequestAction } from './types';

export function* login({ payload }: SignInSuccessAction) {
  yield put(loginUser(payload));
  yield put(loginUserSuccess());
  yield put(push(paths.deliveriesPage));
}

export function* logout() {
  try {
    yield call(api.fcm.disableNotifications);
  } catch (e) {
    // caching error
  } finally {
    yield put(clearTokens());
  }
}

export function* sendFCMToken({ payload }: SendFCMTokenRequestAction) {
  try {
    yield call(api.fcm.sendNotificationsToken, payload);
    yield put(sendFCMTokenSuccess());
  } catch (error) {
    toast.error(error?.response?.data?.message || '');
    yield put(sendFCMTokenFailure(error));
  }
}

export default function* appSaga() {
  yield takeLatest(SIGN_IN_USER_SUCCESS, login);
  yield takeLatest(LOGOUT, logout);
  yield takeLatest(SEND_FCM_TOKEN_REQUEST, sendFCMToken);
}
