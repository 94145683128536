import { axios } from '..';
import { GetQuotesRequest, GetQuotesResponse } from './types';

const endpoints = {
  quotes: 'quotes',
};

export default {
  getQuotes: (params: GetQuotesRequest) =>
    axios.post<GetQuotesRequest, GetQuotesResponse>(endpoints.quotes, params),
};
