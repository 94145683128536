/* eslint-disable no-template-curly-in-string */
export default {
  components: {
    loadingIndicator: {
      loading: 'Chargement',
    },
  },
  containers: {
    notFoundPage: {
      header: 'Page non trouvée',
    },
    homePage: {
      header: 'Modèle de Projet React',
      logout: 'Déconnexion',
    },
    signInPage: {
      header: "C'est le composant SignInPage!",
      email: 'Email',
      password: 'Mot de passe',
      submit: 'Se connecter',
      waiting: 'Please, waiting...',
      emailInvalid: 'Please enter valid email',
      emailRequired: 'Please enter your email',
      passwordRequired: 'Please enter password',
      passwordMinLength: 'Your password is too short',
      passwordMaxLength: 'Your password is too long',
    },
    userDetail: {
      name: 'Prénom',
      email: 'Email',
    },
    deliveriesPage: {
      header: "C'est le composant DeliveriesPage!",
    },
    clientsPage: {
      header: "C'est le composant ClientsPage!",
    },
  },
};
