import ClientsPage from 'containers/ClientsPage/Loadable';
import DeliveriesPage from 'containers/DeliveriesPage/Loadable';
import DriverCompaniesPage from 'containers/DriverCompaniesPage/Loadable';
import DriversPage from 'containers/DriversPage/Loadable';
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import SignInPage from 'containers/SignInPage/Loadable';

export const paths = {
  homePage: '/',
  signInPage: '/sign-in',
  deliveriesPage: '/deliveries',
  forgotPasswordPage: '/forgot-password/:key',
  clientsPage: '/clients',
  driverCompaniesPage: '/companies',
  redirectPage: '/sign-in',
  driversPage: '/drivers',
  notFoundPage: '',
  onboardingPage: 'https://www.getcride.com/',
};

const routes = () => [
  {
    path: paths.homePage,
    component: DeliveriesPage,
    exact: true,
    auth: true,
  },
  {
    path: paths.deliveriesPage,
    component: DeliveriesPage,
    auth: true,
  },
  {
    path: paths.driversPage,
    component: DriversPage,
    exact: true,
    auth: true,
  },
  {
    path: paths.signInPage,
    component: SignInPage,
  },
  {
    path: paths.forgotPasswordPage,
    component: ForgotPasswordPage,
  },
  {
    path: paths.clientsPage,
    component: ClientsPage,
    auth: true,
  },
  {
    path: paths.driverCompaniesPage,
    component: DriverCompaniesPage,
    auth: true,
  },
  {
    path: paths.notFoundPage,
    component: NotFoundPage,
  },
];

export default routes;
