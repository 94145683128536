/**
 *
 * Template selectors
 *
 */

import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { initialState } from './reducer';

/**
 * Direct selector to the template state domain
 */

const selectTemplateStateDomain = (state: RootState) =>
  state.template || initialState;

/**
 * Other specific selectors
 */

const makeSelectSideMenuOpened = () =>
  createSelector(
    selectTemplateStateDomain,
    ({ sideMenuOpened }) => sideMenuOpened,
  );

/**
 * Default selector used by Template
 */

const makeSelectTemplateState = () =>
  createSelector(selectTemplateStateDomain, subState => subState);

export default makeSelectTemplateState;
export { selectTemplateStateDomain, makeSelectSideMenuOpened };
