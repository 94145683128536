/**
 *
 * DeliveriesPage constants
 *
 */

export const GET_DELIVERIES_REQUEST = 'DeliveriesPage/GET_DELIVERIES_REQUEST';
export const GET_DELIVERIES_FAILURE = 'DeliveriesPage/GET_DELIVERIES_FAILURE';
export const GET_DELIVERIES_SUCCESS = 'DeliveriesPage/GET_DELIVERIES_SUCCESS';
export const GET_DELIVERY_DETAIL_REQUEST =
  'DeliveriesPage/GET_DELIVERY_DETAIL_REQUEST';
export const GET_DELIVERY_DETAIL_FAILURE =
  'DeliveriesPage/GET_DELIVERY_DETAIL_FAILURE';
export const GET_DELIVERY_DETAIL_SUCCESS =
  'DeliveriesPage/GET_DELIVERY_DETAIL_SUCCESS';
export const UPDATE_DELIVERY_REQUEST = 'DeliveriesPage/UPDATE_DELIVERY_REQUEST';
export const UPDATE_DELIVERY_FAILURE = 'DeliveriesPage/UPDATE_DELIVERY_FAILURE';
export const UPDATE_DELIVERY_SUCCESS = 'DeliveriesPage/UPDATE_DELIVERY_SUCCESS';
export const GET_DRIVERS_REQUEST = 'DeliveriesPage/GET_DRIVERS_REQUEST';
export const GET_DRIVERS_FAILURE = 'DeliveriesPage/GET_DRIVERS_FAILURE';
export const GET_DRIVERS_SUCCESS = 'DeliveriesPage/GET_DRIVERS_SUCCESS';
export const SELECT_DELIVERY = 'DeliveriesPage/SELECT_DELIVERY';
export const DRIVER_DETAILS_REQUEST = 'DeliveriesPage/SELECT_DELIVERY_REQUEST';
export const DRIVER_DETAILS_SUCCESS = 'DeliveriesPage/DRIVER_DETAILS_SUCCESS';
export const DRIVER_DETAILS_FAILURE = 'DeliveriesPage/DRIVER_DETAILS_FAILURE';

export const SEARCH_DRIVERS_REQUEST = 'DeliveriesPage/SEARCH_DRIVERS_REQUEST';
export const SEARCH_DRIVERS_SUCCESS = 'DeliveriesPage/SEARCH_DRIVERS_SUCCESS';
export const SEARCH_DRIVERS_FAILURE = 'DeliveriesPage/SEARCH_DRIVERS_FAILURE';

export const SEARCH_CLIENTS_REQUEST = 'DeliveriesPage/SEARCH_CLIENTS_REQUEST';
export const SEARCH_CLIENTS_SUCCESS = 'DeliveriesPage/SEARCH_CLIENTS_SUCCESS';
export const SEARCH_CLIENTS_FAILURE = 'DeliveriesPage/SEARCH_CLIENTS_FAILURE';

export const SHOW_DELIVERY_DETAIL_MODAL =
  'DeliveriesPage/SHOW_DELIVERY_DETAIL_MODAL';
export const HIDE_DELIVERY_DETAIL_MODAL =
  'DeliveriesPage/HIDE_DELIVERY_DETAIL_MODAL';
export const ASSIGN_DRIVER_REQUEST = 'DeliveriesPage/ASSIGN_DRIVER_REQUEST';
export const ASSIGN_DRIVER_FAILURE = 'DeliveriesPage/ASSIGN_DRIVER_FAILURE';
export const ASSIGN_DRIVER_SUCCESS = 'DeliveriesPage/ASSIGN_DRIVER_SUCCESS';
export const SET_DELIVERY_QUERY_TYPE = 'DeliveriesPage/SET_DELIVERY_QUERY_TYPE';
export const SET_DELIVERY_LIST_PAGE = 'DeliveriesPage/SET_DELIVERY_LIST_PAGE';
export const DELIVERY_PAGE_SIZE = 9;

export const GET_COMPANIES_REQUEST = 'DeliveriesPage/GET_COMPANIES_REQUEST';
export const GET_COMPANIES_FAILURE = 'DeliveriesPage/GET_COMPANIES_FAILURE';
export const GET_COMPANIES_SUCCESS = 'DeliveriesPage/GET_COMPANIES_SUCCESS';

export const GENERATE_REPORT_REQUEST = 'DeliveriesPage/GENERATE_REPORT_REQUEST';
export const GENERATE_REPORT_FAILURE = 'DeliveriesPage/GENERATE_REPORT_FAILURE';
export const GENERATE_REPORT_SUCCESS = 'DeliveriesPage/GENERATE_REPORT_SUCCESS';

export const CLEAR_REPORT_ERROR = 'DeliveriesPage/CLEAR_REPORT_ERROR';
export const SET_IS_OPEN_REPORT_POPUP =
  'DeliveriesPage/SET_IS_OPEN_REPORT_POPUP';

export const GET_QUOTES_REQUEST = 'DeliveriesPage/GET_QUOTES_REQUEST';
export const GET_QUOTES_FAILURE = 'DeliveriesPage/GET_QUOTES_FAILURE';
export const GET_QUOTES_SUCCESS = 'DeliveriesPage/GET_QUOTES_SUCCESS';
export const CLEAR_QUOTES_DATA = 'DeliveriesPage/CLEAR_QUOTES_DATA';
