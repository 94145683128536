import * as api from 'api';
import { ClientPagination } from 'api/clients/types';
import { AxiosResponse } from 'axios';
import {
  getClientsFailure,
  getClientsSuccess,
  updateClientFailure,
} from 'containers/ClientsPage/actions';
import {
  CLIENTS_REQUEST,
  UPDATE_CLIENT_REQUEST,
} from 'containers/ClientsPage/constants';
import {
  ClientsActionTypes,
  UpdateClientRequestAction,
} from 'containers/ClientsPage/types';
import { call, put, takeLatest } from 'redux-saga/effects';

import { updateClientSuccess } from './actions';

export function* getClients({ payload }: ClientsActionTypes) {
  try {
    const response: AxiosResponse<ClientPagination> = yield call(
      api.clients.getClients,
      payload,
    );
    yield put(getClientsSuccess(response.data));
  } catch ({ response }) {
    yield put(getClientsFailure(response.status));
  }
}

export function* updateClient({ payload }: UpdateClientRequestAction) {
  try {
    yield call(api.clients.update, payload);

    yield put(updateClientSuccess());
  } catch (error) {
    yield put(updateClientFailure(error));
  }
}

export default function* clientsPageSaga() {
  yield takeLatest(UPDATE_CLIENT_REQUEST, updateClient);
  yield takeLatest(CLIENTS_REQUEST, getClients);
}
