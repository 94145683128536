import produce, { Draft } from 'immer';

import {
  GET_BACKEND_VERSION_FAILURE,
  GET_BACKEND_VERSION_REQUEST,
  GET_BACKEND_VERSION_SUCCESS,
} from './constants';
import { BackendActionTypes, BackendState } from './types';

export const initialState: BackendState = {
  loading: false,
  version: '',
};

const appReducer = produce(
  (draft: Draft<BackendState>, action: BackendActionTypes) => {
    switch (action.type) {
      case GET_BACKEND_VERSION_REQUEST:
        draft.loading = true;
        break;
      case GET_BACKEND_VERSION_SUCCESS:
        draft.loading = false;
        draft.version = action.payload;
        break;
      case GET_BACKEND_VERSION_FAILURE:
        draft.loading = false;
        break;
      default:
    }
  },
  initialState,
);

export default appReducer;
