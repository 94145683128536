import { DriverCompany, DriverCompanyStatus } from 'api/driverCompanies/types';
import produce, { Draft } from 'immer';

import {
  GET_COMPANY_INSURANCE_FAILURE,
  GET_COMPANY_INSURANCE_REQUEST,
  GET_COMPANY_INSURANCE_SUCCESS,
  GET_DRIVER_COMPANIES_FAILURE,
  GET_DRIVER_COMPANIES_REQUEST,
  GET_DRIVER_COMPANIES_SUCCESS,
  GET_DRIVERS_FAILURE,
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  UPDATE_COMPANY_INSURANCE_FAILURE,
  UPDATE_COMPANY_INSURANCE_REQUEST,
  UPDATE_COMPANY_INSURANCE_SUCCESS,
  UPDATE_DRIVER_COMPANY_FAILURE,
  UPDATE_DRIVER_COMPANY_REQUEST,
  UPDATE_DRIVER_COMPANY_SUCCESS,
} from './constants';
import { DriverCompaniesActionTypes, DriverCompaniesState } from './types';

const driverCompanyInitialState: DriverCompany = {
  id: 0,
  name: '',
  phoneNumber: '',
  taxId: '',
  website: '',
  country: '',
  state: '',
  city: '',
  address: '',
  owner: {
    id: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  status: 'review' as DriverCompanyStatus,
};

export const initialState: DriverCompaniesState = {
  drivers: {
    totalCount: 0,
    pageNumber: 0,
    totalPages: 0,
    loading: false,
    error: undefined,
    data: [],
  },
  driverCompaniesPagination: {
    totalCount: 0,
    pageNumber: 0,
    totalPages: 1,
    data: [{ ...driverCompanyInitialState }],
  },
  driverCompany: {
    ...driverCompanyInitialState,
  },
  companyInsurance: {
    id: 0,
    name: '',
    certificateNumber: '',
    companyId: 0,
    expiration: '',
    companyInsurancePhotos: [],
  },
  photos: [],
  loading: false,
  hasError: false,
};

const driverCompaniesPageReducer = produce(
  (draft: Draft<DriverCompaniesState>, action: DriverCompaniesActionTypes) => {
    switch (action.type) {
      case GET_DRIVER_COMPANIES_REQUEST:
      case UPDATE_DRIVER_COMPANY_REQUEST:
      case UPDATE_COMPANY_INSURANCE_REQUEST:
        draft.loading = true;
        break;
      case GET_DRIVER_COMPANIES_FAILURE:
      case GET_COMPANY_INSURANCE_FAILURE:
      case UPDATE_DRIVER_COMPANY_FAILURE:
      case UPDATE_COMPANY_INSURANCE_FAILURE:
        draft.loading = false;
        draft.hasError = true;
        draft.error = action.payload;
        break;
      case GET_COMPANY_INSURANCE_REQUEST:
        draft.companyInsurance = initialState.companyInsurance;
        draft.loading = true;
        draft.hasError = false;
        draft.error = undefined;
        break;
      case GET_DRIVER_COMPANIES_SUCCESS:
        draft.loading = false;
        draft.driverCompaniesPagination = action.payload;
        break;
      case UPDATE_DRIVER_COMPANY_SUCCESS:
        draft.loading = false;
        break;
      case GET_COMPANY_INSURANCE_SUCCESS:
        draft.loading = false;
        draft.companyInsurance = {
          ...draft.companyInsurance,
          ...action.payload,
        };
        break;
      case UPDATE_COMPANY_INSURANCE_SUCCESS:
        draft.loading = false;
        break;
      case GET_DRIVERS_REQUEST:
        draft.drivers.loading = true;
        draft.drivers.error = undefined;
        break;
      case GET_DRIVERS_SUCCESS:
        draft.drivers.loading = false;
        draft.drivers.data = action.payload.data;
        draft.drivers.totalCount = action.payload.totalCount;
        draft.drivers.totalPages = action.payload.totalPages;
        draft.drivers.pageNumber = action.payload.pageNumber;
        break;
      case GET_DRIVERS_FAILURE:
        draft.drivers.loading = false;
        draft.drivers.error = action.payload.message;
        break;
      default:
    }
  },
  initialState,
);

export default driverCompaniesPageReducer;
