import { getBackendVersion } from 'containers/Backend/actions';
import { makeSelectBackendVersion } from 'containers/Backend/selectors';
import React, { useEffect } from 'react';
import { useAction, useSelector } from 'utils/hooks';

interface Props {
  sideMenuOpen?: boolean;
}

const AppVersion = ({ sideMenuOpen }: Props) => {
  const backendVersion = useSelector(makeSelectBackendVersion());

  const getBackendVersionAction = useAction(getBackendVersion);

  useEffect(() => {
    getBackendVersionAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`app-version ${!sideMenuOpen && 'closed'}`}>
      <span>
        {sideMenuOpen && 'Frontend Version:'} {process.env.REACT_APP_VERSION}.
        {process.env.REACT_APP_GIT_COMMIT_COUNT}
      </span>
      {backendVersion && (
        <span>
          {sideMenuOpen && 'Backend Version:'} {backendVersion}
        </span>
      )}
    </div>
  );
};

export default AppVersion;
