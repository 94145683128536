import produce, { Draft } from 'immer';

import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from './constants';
import { ForgotPasswordActionTypes, ForgotPasswordState } from './types';

export const initialState: ForgotPasswordState = {
  loading: false,
  hasError: false,
  error: undefined,
};

const forgotPasswordPageReducer = produce(
  (draft: Draft<ForgotPasswordState>, action: ForgotPasswordActionTypes) => {
    switch (action.type) {
      case FORGOT_PASSWORD_REQUEST:
        draft.loading = true;
        break;
      case FORGOT_PASSWORD_FAILURE:
        draft.loading = false;
        draft.hasError = true;
        draft.error = action.payload;
        break;
      case FORGOT_PASSWORD_SUCCESS:
        return initialState;
      default:
    }
  },
  initialState,
);

export default forgotPasswordPageReducer;
