import { connectRouter } from 'connected-react-router';
import appReducer from 'containers/App/reducer';
import backendReducer from 'containers/Backend/reducer';
import clientsPageReducer from 'containers/ClientsPage/reducer';
import deliveriesPageReducer from 'containers/DeliveriesPage/reducer';
import driverCompaniesPageReducer from 'containers/DriverCompaniesPage/reducer';
import driversPageReducer from 'containers/DriversPage/reducer';
import feedbackMessageReducer from 'containers/FeedbackMessage/reducer';
import forgotPasswordPageReducer from 'containers/ForgotPasswordPage/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import signInPageReducer from 'containers/SignInPage/reducer';
import templateReducer from 'containers/Template/reducer';
import { combineReducers } from 'redux';
import history from 'utils/history';

const rootReducer = combineReducers({
  router: connectRouter(history),
  languageProvider: languageProviderReducer,
  app: appReducer,
  signInPage: signInPageReducer,
  deliveriesPage: deliveriesPageReducer,
  driversPage: driversPageReducer,
  forgotPasswordPage: forgotPasswordPageReducer,
  template: templateReducer,
  clientsPage: clientsPageReducer,
  feedbackMessage: feedbackMessageReducer,
  backend: backendReducer,
  driverCompaniesPage: driverCompaniesPageReducer,
});

export { rootReducer };
