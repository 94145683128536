import React, { FC, useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'utils/hooks';

import messages from './messages';
import makeSelectFeedbackMessageId, {
  makeSelectFeedbackMessageType,
} from './selectors';

export const FeedbackMessage: FC = () => {
  const { formatMessage } = useIntl();
  const currentId = useSelector(makeSelectFeedbackMessageId());
  const messageType = useSelector(makeSelectFeedbackMessageType());
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (currentId) {
      setVisible(true);
    }
  }, [currentId]);

  const handleHide = (hide: boolean) => {
    setVisible(hide);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        zIndex: 9999,
      }}
    >
      <Toast
        show={visible}
        onClose={() => handleHide(false)}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-2">Success</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>
          {messageType === 'save' && formatMessage(messages.saveSuccess)}
          {messageType === 'update' && formatMessage(messages.updateSuccess)}
          {messageType === 'delete' && formatMessage(messages.deleteSuccess)}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default FeedbackMessage;
