import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import 'firebase/firebase-messaging';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { render } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import setupFirebase from 'setupFirebase';
import { history, persistor, store } from 'store';
import 'utils/networkExtension';
import moment from 'moment-timezone';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import { DEFAULT_TIMEZONE } from 'utils/constants';

const targetElement = document.getElementById('root');
const {
  REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_DNS,
  REACT_APP_VERSION,
  REACT_APP_GIT_COMMIT_COUNT,
} = process.env;

setupFirebase();

Sentry.init({
  dsn: REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: REACT_APP_SENTRY_ENV,
  release: `${REACT_APP_VERSION}.${REACT_APP_GIT_COMMIT_COUNT}`,
});

moment.tz.setDefault(DEFAULT_TIMEZONE);

render(
  <Provider store={store} context={ReactReduxContext}>
    <LanguageProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App context={ReactReduxContext} />
          <ToastContainer
            position="top-center"
            autoClose={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            transition={Slide}
          />
        </ConnectedRouter>
      </PersistGate>
    </LanguageProvider>
  </Provider>,
  targetElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
