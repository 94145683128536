import {
  CompanyInsurance,
  DriverCompaniesPagination,
  DriverCompaniesRequestParams,
  DriverCompanyFormValues,
} from 'api/driverCompanies/types';
import { GetDriversRequest, GetDriversResponse } from 'api/drivers/types';
import { AxiosError } from 'axios';

import {
  GET_COMPANY_INSURANCE_FAILURE,
  GET_COMPANY_INSURANCE_REQUEST,
  GET_COMPANY_INSURANCE_SUCCESS,
  GET_DRIVER_COMPANIES_FAILURE,
  GET_DRIVER_COMPANIES_REQUEST,
  GET_DRIVER_COMPANIES_SUCCESS,
  GET_DRIVERS_FAILURE,
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  UPDATE_COMPANY_INSURANCE_FAILURE,
  UPDATE_COMPANY_INSURANCE_REQUEST,
  UPDATE_COMPANY_INSURANCE_SUCCESS,
  UPDATE_DRIVER_COMPANY_FAILURE,
  UPDATE_DRIVER_COMPANY_REQUEST,
  UPDATE_DRIVER_COMPANY_SUCCESS,
} from './constants';
import {
  DriverCompaniesActionTypes,
  UpdateDriverCompanyRequestAction,
} from './types';

export const getDriverCompanies = (
  params: DriverCompaniesRequestParams,
): DriverCompaniesActionTypes => ({
  type: GET_DRIVER_COMPANIES_REQUEST,
  payload: params,
});

export const getDriverCompaniesSuccess = (
  payload: DriverCompaniesPagination,
): DriverCompaniesActionTypes => ({
  type: GET_DRIVER_COMPANIES_SUCCESS,
  payload,
});

export const getDriverCompaniesFailure = (
  payload: number,
): DriverCompaniesActionTypes => ({
  type: GET_DRIVER_COMPANIES_FAILURE,
  payload,
});

export const updateDriverCompany = (
  payload: DriverCompanyFormValues,
): UpdateDriverCompanyRequestAction => ({
  type: UPDATE_DRIVER_COMPANY_REQUEST,
  payload,
});

export const updateDriverCompanySuccess = (
  payload: number,
): DriverCompaniesActionTypes => ({
  type: UPDATE_DRIVER_COMPANY_SUCCESS,
  payload,
});

export const updateDriverCompanyFailure = (
  payload: number,
): DriverCompaniesActionTypes => ({
  type: UPDATE_DRIVER_COMPANY_FAILURE,
  payload,
});

export const getCompanyInsurance = (
  id: number,
): DriverCompaniesActionTypes => ({
  type: GET_COMPANY_INSURANCE_REQUEST,
  payload: id,
});

export const getCompanyInsuranceSuccess = (
  payload: CompanyInsurance,
): DriverCompaniesActionTypes => ({
  type: GET_COMPANY_INSURANCE_SUCCESS,
  payload,
});

export const getCompanyInsuranceFailure = (
  payload: number,
): DriverCompaniesActionTypes => ({
  type: GET_COMPANY_INSURANCE_FAILURE,
  payload,
});

export const updateCompanyInsurance = (
  payload: CompanyInsurance,
): DriverCompaniesActionTypes => ({
  type: UPDATE_COMPANY_INSURANCE_REQUEST,
  payload,
});

export const updateCompanyInsuranceSuccess = (
  payload: number,
): DriverCompaniesActionTypes => ({
  type: UPDATE_COMPANY_INSURANCE_SUCCESS,
  payload,
});

export const updateCompanyInsuranceFailure = (
  payload: number,
): DriverCompaniesActionTypes => ({
  type: UPDATE_COMPANY_INSURANCE_FAILURE,
  payload,
});

export const getDriversRequestAction = (
  payload: GetDriversRequest,
): DriverCompaniesActionTypes => ({
  type: GET_DRIVERS_REQUEST,
  payload,
});

export const getDriversSuccessAction = (
  payload: GetDriversResponse,
): DriverCompaniesActionTypes => ({
  type: GET_DRIVERS_SUCCESS,
  payload,
});

export const getDriversFailureAction = (
  payload: AxiosError,
): DriverCompaniesActionTypes => ({
  type: GET_DRIVERS_FAILURE,
  payload,
});
