import { axios } from 'api';
import { FormValues } from 'containers/ForgotPasswordPage/types';

import { SignInRequest } from './types';

export const endpoints = {
  signIn: '/auth',
  resetPassword: 'auth/reset-password',
};

export default {
  signInUser: (userData: SignInRequest) =>
    axios.post(endpoints.signIn, { ...userData, accountType: 'admin' }),
  resetPassword: (form: FormValues) =>
    axios.post(endpoints.resetPassword, form),
};
