import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { initialState } from './reducer';

const selectBackendDomain = (state: RootState) => state.backend || initialState;

const makeSelectBackendVersion = () =>
  createSelector(selectBackendDomain, ({ version }) => version);

const makeSelectBackendVersionIsLoading = () =>
  createSelector(selectBackendDomain, ({ loading }) => loading);

const makeSelectBackend = () =>
  createSelector(selectBackendDomain, subState => subState);

export default makeSelectBackend;
export { makeSelectBackendVersion, makeSelectBackendVersionIsLoading };
