import { axios } from 'api';
import rootAxios from 'axios';

import {
  CompanyInsurance,
  CompanyInsuranceRequest,
  DriverCompaniesPagination,
  DriverCompaniesRequestParams,
  DriverCompany,
  InsurancePhotoRequest,
} from './types';

export const endpoints = {
  driverCompanies: 'driver-companies',
  companyInsurance: 'driver-companies/insurance',
  insurancePhoto: 'driver-companies/insurance/photo',
};

export default {
  getDriverCompanies: (params: DriverCompaniesRequestParams) =>
    axios.get<DriverCompaniesPagination>(endpoints.driverCompanies, {
      params,
    }),
  getCompanyInsurance: (id: number) =>
    axios.get<CompanyInsurance>(`${endpoints.companyInsurance}/${id}`),
  updateDriverCompany: (data: DriverCompany) =>
    axios.put(`${endpoints.driverCompanies}/${data.id}`, data),
  requireCompanyInsurance: (data: CompanyInsurance) =>
    axios.post(endpoints.companyInsurance, data),
  addCompanyInsurance: (data: CompanyInsuranceRequest) =>
    axios.post(endpoints.companyInsurance, data),
  updateCompanyInsurance: (data: CompanyInsuranceRequest) =>
    axios.put(`${endpoints.companyInsurance}/${data.id}`, data),
  requireCompanyInsurancePhotoUrls: (data: InsurancePhotoRequest) =>
    axios.post(`${endpoints.insurancePhoto}`, data),
  sendPhotoToAws: (url: string, file: File) =>
    rootAxios.put(url, file, {
      headers: { 'Content-Type': file.type },
    }),
};
