import {
  Driver,
  DriverForm,
  DriverForMapData,
  DriversPagination,
  DriversRequestParams,
  GetDriversRequest,
  GetDriversResponse,
  GetDriverStatusResponse,
  LicensePhotoRequest,
} from 'api/drivers/types';

import { axios } from '../index';
export const endpoints = {
  adminDrivers: 'admin/drivers',
  map: 'admin/map',
  states: 'admin/states',
  licensePhoto: 'admin/license-file',
  drivers: 'drivers',
  status: 'drivers/status',
};

export default {
  getDriver: (id: string) =>
    axios.get<Driver>(`${endpoints.adminDrivers}/${id}`),
  getMapDrivers: () => axios.get<DriverForMapData>(endpoints.map),
  addDriver: (driver: DriverForm) => axios.post(endpoints.adminDrivers, driver),
  updateDriver: (driver: DriverForm) =>
    axios.put(`${endpoints.adminDrivers}/${driver.id}`, driver),
  deleteDriver: (id: string) => axios.delete(`${endpoints.adminDrivers}/${id}`),
  getStates: () => axios.get(endpoints.states),
  getAdminDrivers: (params: DriversRequestParams) =>
    axios.get<DriversPagination>(endpoints.adminDrivers, { params }),
  getDrivers: (params: GetDriversRequest) =>
    axios.get<GetDriversResponse>(endpoints.drivers, { params }),
  requireLicensePhotoEndoint: (data: LicensePhotoRequest) =>
    axios.post(endpoints.licensePhoto, data),
  getStatus: () => axios.get<GetDriverStatusResponse>(endpoints.status),
};
