/**
 *
 * App constants
 *
 */

export const LOGIN = 'App/LOGIN_USER';
export const LOGIN_SUCCESS = 'App/LOGIN_USER_SUCCESS';
export const LOGOUT = 'App/LOGOUT_USER';
export const CLEAR_TOKENS = 'App/CLEAR_TOKENS';
export const UPDATE_TOKEN = 'App/UPDATE_TOKEN';
export const SEND_FCM_TOKEN_REQUEST = 'App/SEND_FCM_TOKEN_REQUEST';
export const SEND_FCM_TOKEN_FAILURE = 'App/SEND_FCM_TOKEN_FAILURE';
export const SEND_FCM_TOKEN_SUCCESS = 'App/SEND_FCM_TOKEN_SUCCESS';
export const VAPID_KEY =
  'BCcKUe6gGPSsOdgE323302E6earwjXxK2Ox0wMdQUoomfUl2OpcyCULBPOcHXhlMOzl9M8O_B9zNT10cNJdPgnA';
