import produce, { Draft } from 'immer';

import {
  CLIENTS_FAILURE,
  CLIENTS_REQUEST,
  CLIENTS_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
} from './constants';
import { ClientsActionTypes, ClientsState } from './types';

export const initialState: ClientsState = {
  clientPagination: {
    totalCount: 0,
    pageNumber: 0,
    totalPages: 1,
    data: [],
  },
  updateClient: {
    loading: false,
    hasError: false,
    error: undefined,
  },
  loading: false,
  hasError: false,
};

const clientsPageReducer = produce(
  (draft: Draft<ClientsState>, action: ClientsActionTypes) => {
    switch (action.type) {
      case CLIENTS_REQUEST:
        draft.loading = true;
        break;
      case CLIENTS_FAILURE:
        draft.loading = false;
        draft.hasError = true;
        draft.error = action.payload;
        break;
      case CLIENTS_SUCCESS:
        draft.loading = false;
        draft.clientPagination = action.payload;
        break;
      case UPDATE_CLIENT_REQUEST:
        draft.updateClient.loading = true;
        draft.updateClient.error = undefined;
        draft.updateClient.hasError = false;
        break;
      case UPDATE_CLIENT_SUCCESS:
        draft.updateClient.loading = false;
        break;
      case UPDATE_CLIENT_FAILURE:
        draft.updateClient.loading = false;
        draft.updateClient.hasError = true;
        draft.updateClient.error = action.payload;
        break;
      default:
    }
  },
  initialState,
);

export default clientsPageReducer;
